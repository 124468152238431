import React, { useContext } from 'react'
import { GoArrowRight } from 'react-icons/go'
import { Link } from 'react-router-dom'
import { LanguageContext } from '../../../context/LanguageContext'

const CallToAction = ({data}) => {
    const {language} = useContext(LanguageContext);
    const info = data;
    const d = info[language];
  return (
    <div className='md:mb-[144px] mb-[64px] max-w-[1440px] md:mx-auto mx-4 bg-primary py-[56px] md:px-[245px] px-[20px] rounded-xl text-white'>
      <h1 className='md:text-[64px] text-[30px] text-center font-semibold leading-[1] md:mb-9 mb-3'>{d.title}</h1>
      <p className='md:text-[28px] text-[14px] text-center'>{d.desc}</p>
      <Link target='_blank' to='https://bit.ly/tkambious-sgomez' className='duration-300 md:text-[34px] flex items-center justify-center md:mx-[270px] mt-5 bg-white group hover:bg-secondary hover:text-white text-dark rounded-lg gap-4 py-2 pl-4 pr-2'>Schedule a Meeting <span><GoArrowRight className='bg-secondary group-hover:bg-darklight text-white md:py-2 py-1 md:text-5xl text-3xl rounded-lg' /></span></Link>
    </div>
  )
}

export default CallToAction
