import React from 'react'
import Process from '../../common_sections/Process'
import img from '../../../../assets/credit_process.png'

const CreditProcess = () => {

    const data = {
        en: [
            {
                title: "Choose Your Assets",
                desc: "Select the productive asset or equipment you need to grow your business."
            },
            {
                title: "Flexible Credit Terms",
                desc: "Decide on a term that aligns with your business's revenue—6, 12, 18, or 24 months."
            },
            {
                title: "Start with 20% Down",
                desc: "Secure your equipment with an affordable initial payment."
            },
            {
                title: "Grow Your Business",
                desc: "Use the equipment to enhance operations and profitability while making fixed monthly payments."
            },
            {
                title: "Ownership Transfer",
                desc: "At the end of the term, take full ownership of the equipment or asset."
            },
        ],
        es: [
            {
                title: "Elija sus activos",
                desc: "Seleccione el activo o equipo productivo que necesita para hacer crecer su negocio."
            },
            {
                title: "Términos de crédito flexibles",
                desc: "Decida un plazo que se alinee con los ingresos de su empresa: 6, 12, 18 o 24 meses."
            },
            {
                title: "Comience con un 20% de pago inicial",
                desc: "Asegura tu equipo con un pago inicial asequible."
            },
            {
                title: "Haga crecer su negocio",
                desc: "Utilice el equipo para mejorar las operaciones y la rentabilidad mientras realiza pagos mensuales fijos."
            },
            {
                title: "Transferencia de propiedad",
                desc: "Al finalizar el plazo, tomar la propiedad total del equipo o activo."
            },
        ],
    }

  return (
    <div>
      <Process
      info={data}
      entitle="Flexible Leasing Process with Infinito Capital"
      estitle="Proceso de arrendamiento flexible con Infinito Capital"
      img={img}
      />
    </div>
  )
}

export default CreditProcess
