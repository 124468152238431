import React, { useContext, useState } from 'react';
import logo from '../assets/logo.png';
import { LanguageContext } from '../context/LanguageContext';
import { Link } from 'react-router-dom';
import { IoIosArrowDown } from "react-icons/io";
import english from "../assets/englishcircle.png";
import spanish from "../assets/spanishcircle.png";
import { motion } from "framer-motion";
import { MdCall } from "react-icons/md";
import { IoMenu, IoClose } from "react-icons/io5";

const Header = () => {
    const { language, toggleLanguage } = useContext(LanguageContext);

    const navigation = {
        en: [
            {
                name: "Who we are",
                link: "/who-we-are",
            },
            {
                name: "Services",
                link: {javascript:void(0)},
                dropdown: [
                    { name: "Factoring", link: "/services/factoring" },
                    { name: "Lease", link: "/services/lease" },
                    { name: "Credit", link: "/services/credit" },
                    { name: "Capital Loans", link: "/services/capital-loans" },
                    { name: "Inventory Financing", link: "/services/inventory-financing" },
                    { name: "Real Estate", link: "/services/real-estate" },
                    { name: "Financial management", link: "/services/financial-management" },
                ],
            },
            { name: "How it works", link: "/how-it-works" },

        ],
        es: [
            {
                name: "Quienes somos",
                link: "/who-we-are",
            },
            {
                name: "Servicios",
                link: "#",
                dropdown: [
                  { name: "Factoring", link: "/services/factoring" },
                  { name: "Lease", link: "/services/lease" },
                  { name: "Credit", link: "/services/credit" },
                  { name: "Capital Loans", link: "/services/capital-loans" },
                  { name: "Inventory Financing", link: "/services/inventory-financing" },
                  { name: "Real Estate", link: "/services/real-estate" },
                  { name: "Financial management", link: "/services/financial-management" },
              ],
            },
            { name: "Cómo funciona", link: "/how-it-works" },
        ],
    };

    const nav = navigation[language];

    const dropdownVariants = {
        hidden: { opacity: 0, height: 0 },
        visible: { opacity: 1, height: "auto" },
    };

    const [openIndex, setOpenIndex] = useState(null); // Track which dropdown is open
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [mobileDropdownOpen, setMobileDropdownOpen] = useState(null);
    const toggleMobileMenu = () => setMobileMenuOpen(!mobileMenuOpen);

    const handleMouseEnter = (index) => setOpenIndex(index);
    const handleMouseLeave = () => setOpenIndex(null);
    return (
        <motion.header
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="bg-white shadow fixed w-full z-[111]"
        >
            <div className="container mx-auto flex justify-between items-center px-6 md:py-0 py-2 z-10">
                <div>
                    <Link to='/'><img src={logo} alt="Logo" className="h-10" /></Link>
                </div>
                {/* Desktop */}
                <nav className='md:block hidden'>
                    <ul className="flex space-x-6">
                        {nav.map((item, index) => (
                            <li
                                key={index}
                                className="relative py-6"
                                onMouseEnter={() => handleMouseEnter(index)}
                                onMouseLeave={handleMouseLeave}
                            >
                                <Link
                                    to={item.link}
                                    className={`font-semibold text-primary hover:text-gray-600 ${item.dropdown ? "flex items-center gap-2" : ""
                                        }`}
                                >
                                    <div className='flex items-center gap-2'>
                                        {item.name}
                                        {item.icon}
                                    </div>
                                    {item.dropdown ? <IoIosArrowDown /> : ""}
                                </Link>
                                {item.dropdown && (
                                    <motion.ul
                                        className="absolute left-[-3rem] mt-4 bg-white shadow-lg rounded-lg p-2 w-[20rem] overflow-hidden"
                                        initial="hidden"
                                        animate={openIndex === index ? "visible" : "hidden"}
                                        exit="hidden"
                                        variants={dropdownVariants}
                                        transition={{ duration: 0.3 }}
                                    >
                                        {item.dropdown.map((subItem, subIndex) => (
                                            <li key={subIndex}>
                                                <Link
                                                    to={subItem.link}
                                                    className="block px-4 py-2 text-primary border-b duration-300 hover:bg-gray-100"
                                                >
                                                    {subItem.name}
                                                </Link>
                                            </li>
                                        ))}
                                    </motion.ul>
                                )}
                            </li>
                        ))}
                        <li className="relative py-6 text-primary font-semibold">
                            <Link className='flex items-center gap-2'>{language==='en'?'+1 915 955.2646':'+52 656 438.5025'} <MdCall /></Link>
                        </li>
                    </ul>
                </nav>
                <div className='md:flex items-center gap-3 hidden'>
                    <button onClick={toggleLanguage} className="text-sm text-primary rounded px-2 py-1">
                        {language === "en" ? (
                            <p className="flex items-center gap-1 text-lg uppercase">
                                <img alt="tkambios.us" className="w-[20px]" src={spanish} /> Es
                            </p>
                        ) : (
                            <p className="flex items-center gap-1 text-lg uppercase">
                                <img alt="tkambios.us" className="w-[20px]" src={english} /> En
                            </p>
                        )}
                    </button>
                    <Link className='py-2 px-5 text-white rounded-lg bg-primary' to='/contact'>{language === 'es' ? 'Contacto' : 'Contact Us'}</Link>
                </div>

                {/* Mobile */}
                <div className='md:hidden block'>
  <div className='flex items-center'>
    <button onClick={toggleLanguage} className="text-sm text-primary rounded px-2 py-1">
      {language === "en" ? (
        <p className="flex items-center gap-1 text-lg uppercase">
          <img alt="tkambios.us" className="w-[20px]" src={spanish} /> Es
        </p>
      ) : (
        <p className="flex items-center gap-1 text-lg uppercase">
          <img alt="tkambios.us" className="w-[20px]" src={english} /> En
        </p>
      )}
    </button>
    <button
      className="text-3xl text-primary"
      onClick={toggleMobileMenu}
    >
      {mobileMenuOpen ? <IoClose /> : <IoMenu />}
    </button>
  </div>

  <motion.div
    className="absolute left-0 w-full py-4 mt-3 bg-white shadow-lg md:hidden"
    initial={{ height: 0, opacity: 1, display: 'none' }}
    animate={mobileMenuOpen ? { height: "auto", opacity: 1, display: 'block' } : { height: 0, opacity: 0, display: 'none' }}
    exit={{ height: 0, opacity: 0, display: 'none' }}
    transition={{ duration: 0.5, ease: "easeInOut" }}
  >
    <ul >
      <li className="border-b py-2 px-2">
        <Link onClick={() => setMobileMenuOpen(false)} className="font-semibold text-[#130F40] hover:text-gray-600" to="/">
          Home
        </Link>
      </li>
      {nav.map((item, index) => (
        <li
          key={index}
          className="relative border-b py-2 px-2"
          onClick={item.dropdown ? null : () => setMobileMenuOpen(false)}
        >
          <Link to={item.link}
            className={`font-semibold text-[#130F40] hover:text-gray-600 flex items-center gap-2 cursor-pointer ${item.dropdown ? "" : ""
              }`}
            onClick={() => {
              setMobileDropdownOpen(mobileDropdownOpen === index ? null : index);
            }}
          >
            <span>{item.name}</span>
            {item.dropdown && <IoIosArrowDown />}
          </Link>
          {item.dropdown && (
            <motion.ul
              className="overflow-hidden pl-4  space-y-2"
              initial={{ height: 0, opacity: 0 }}
              animate={mobileDropdownOpen === index ? { height: "auto", opacity: 1 } : { height: 0, opacity: 0 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.3, ease: "easeInOut" }}
            >
              {item.dropdown.map((subItem, subIndex) => (
                <li key={subIndex} className="border-b">
                  <Link
                    to={subItem.link}
                    className="block text-[#130F40] hover:text-gray-600 py-1"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    {subItem.name}
                  </Link>
                </li>
              ))}
            </motion.ul>
          )}
        </li>
      ))}
      <li className="border-b py-2 px-2">
        <Link
          className="font-semibold text-[#130F40] hover:text-gray-600"
          to="/contact"
          onClick={() => setMobileMenuOpen(false)}
        >
          {language === "es" ? "Contacto" : "Contact Us"}
        </Link>
      </li>
    </ul>
    <div className="mt-3 flex justify-center">
      <Link
      to='https://bit.ly/tkambious-sgomez'
        className="bg-primary py-2 px-4 text-white rounded-lg"
      target='_blank'
      >
        {language === "en" ? "Schedule a Meeting" : "Programe una reunión"}
      </Link>
    </div>
  </motion.div>
</div>

            </div>
        </motion.header>
    );
};

export default Header;
