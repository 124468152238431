import React from 'react'
import FAQs from '../../common_sections/FAQs'
const data = {
    en: [
      {
        name: "What types of assets can be financed?",
        desc: "We fund both tangible (equipment, machinery) and intangible (intellectual property) assets.",
      },
      {
        name: "Who is eligible for this loan service?",
        desc: "Businesses of all sizes, including SMEs, non-bank-approved firms, and entrepreneurs.",
      },
      {
        name: "What are the repayment terms?",
        desc: "Flexible terms include 6, 12, 18, or 24-month plans with fixed monthly payments.",
      },
      {
        name: "Is refinancing possible?",
        desc: "Yes, you can refinance existing assets to better manage cash flow.",
      },
      {
        name: "How quickly can I access funds?",
        desc: "Funds are disbursed promptly upon approval and contract finalization.",
      },
    ],
    es: [
      {
        name: "¿Qué tipos de activos se pueden financiar?",
        desc: "Financiamos activos tanto tangibles (equipos, maquinaria) como intangibles (propiedad intelectual).",
      },
      {
        name: "¿Quién es elegible para este servicio de préstamo?",
        desc: "Empresas de todos los tamaños, incluidas pymes, empresas no aprobadas por bancos y emprendedores.",
      },
      {
        name: "¿Cuáles son las condiciones de pago?",
        desc: "Los términos flexibles incluyen planes de 6, 12, 18 o 24 meses con pagos mensuales fijos.",
      },
      {
        name: "¿Es posible refinanciar?",
        desc: "Sí, puedes refinanciar activos existentes para administrar mejor el flujo de efectivo.",
      },
      {
        name: "¿Qué tan rápido puedo acceder a los fondos?",
        desc: "Los fondos se desembolsan rápidamente tras la aprobación y finalización del contrato.",
      },
    ],
    
  };


const LoansFAQs = () => {
  return (
    <div>
      <FAQs
      data = {data}
      />
    </div>
  )
}

export default LoansFAQs
