import React from 'react'
import i1 from '../../../../assets/cover-expense.png'
import i2 from '../../../../assets/trade-discount.png'
import i3 from '../../../../assets/manage-cash.png'
import i4 from '../../../../assets/fund-payroll.png'
import i5 from '../../../../assets/purchase-equipments.png'
import i6 from '../../../../assets/diversify-risk.png'
import CommonCards from '../../common_sections/CommonCards'
const FinancialConstraints = () => {
    const data = {
        en: [
            {
              title: "Lower Financing Cost",
              desc: "Compared to conventional credit, this solution reduces overall financing expenses.",
              icon: i1,
            },
            {
              title: "Transparent Pricing",
              desc: "The repurchase cost mirrors the initial sale price, ensuring cost predictability.",
              icon: i2,
            },
            {
              title: "Flexible Access",
              desc: "Access your inventory anytime without penalties for early payments.",
              icon: i3,
            },
            {
              title: "Logistics Financing",
              desc: "Covers costs related to logistics and import expenses.",
              icon: i4,
            },
            {
              title: "Cost-Effective Process",
              desc: "No need for real estate or personal guarantees; quick approvals with minimal costs.",
              icon: i5,
            },
            {
              title: "Payment Discounts",
              desc: "Take advantage of early payment and bulk purchase discounts.",
              icon: i6,
            },
          ],
        es: [
            {
              title: "Menor costo de financiamiento",
              desc: "En comparación con el crédito convencional, esta solución reduce los gastos generales de financiación.",
              icon: i1,
            },
            {
              title: "Precios transparentes",
              desc: "El costo de recompra refleja el precio de venta inicial, lo que garantiza la previsibilidad de los costos.",
              icon: i2,
            },
            {
              title: "Acceso flexible",
              desc: "Acceda a su inventario en cualquier momento sin penalizaciones por pagos anticipados.",
              icon: i3,
            },
            {
              title: "Financiamiento Logístico",
              desc: "Cubre costos relacionados con logística y gastos de importación.",
              icon: i4,
            },
            {
              title: "Proceso rentable",
              desc: "Sin necesidad de garantías inmobiliarias ni personales; aprobaciones rápidas con costos mínimos.",
              icon: i5,
            },
            {
              title: "Descuentos de pago",
              desc: "Aprovecha los descuentos por pago anticipado y compra al por mayor.",
              icon: i6,
            },
          ],


        
    }
  return (
    <div>
      <CommonCards
      data={data}
      entitle="Leasing Benefit with Infinito Capital"
      estitle="Beneficio de arrendamiento con Infinito Capital"
      />
    </div>
  )
}

export default FinancialConstraints
