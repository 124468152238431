import React, { useContext } from 'react'
import arrow from '../../../assets/arrow.png'
import tradeimg from '../../../assets/trade-img.png'
import realimg from '../../../assets/real-estate-img.png'
import icon1 from '../../../assets/trade.png'
import icon2 from '../../../assets/real-estate.png'
import icon3 from '../../../assets/loan.png'
import icon4 from '../../../assets/finance.png'
import icon5 from '../../../assets/order.png'
import { Link } from 'react-router-dom'
import { LanguageContext } from '../../../context/LanguageContext'

const uppercards = {
    en: [
        {
            title: "Trade Financing Solutions",
            desc: "Boost liquidity instantly through national and international factoring, ensuring seamless cash flow for your business.",
            img: tradeimg,
            icon: icon1
        },
        {
            title: "Real Estate Leasing",
            desc: "Empower your business with access to premium real estate leasing options designed to enhance growth.",
            img: realimg,
            icon: icon2
        },
    ],
    es: [
        {
            title: "Soluciones de financiación comercial",
            desc: "Aumente la liquidez al instante a través del factoring nacional e internacional, garantizando un flujo de caja fluido para su negocio.",
            img: tradeimg,
            icon: icon1
        },
        {
            title: "Arrendamiento Inmobiliario",
            desc: "Fortalezca su negocio con acceso a opciones premium de arrendamiento de bienes raíces diseñadas para mejorar el crecimiento.",
            img: realimg,
            icon: icon2
        },
    ],
}


const lowercard = {
    en: [
        {
            title: "Capital Loans",
            desc: "A commercial capital loan backed by real collateral, offering flexible repayment through a structured monthly amortization plan.",
            icon: icon3
        },
        {
            title: "Financial Management",
            desc: "Infinito Capital offers expert financial management with a complete suite of tailored services to support your business goals.",
            icon: icon4
        },
        {
            title: "Purchase Order Financing",
            desc: "Simplify supplier payments with reliable financing for national and international purchase orders.",
            icon: icon5
        },
    ],
    es: [
        {
            title: "Préstamos de capital",
            desc: "Un préstamo de capital comercial respaldado por garantía real, que ofrece un pago flexible a través de un plan de amortización mensual estructurado.",
            icon: icon3
        },
        {
            title: "Gestión Financiera",
            desc: "Infinito Capital ofrece gestión financiera experta con un conjunto completo de servicios personalizados para respaldar sus objetivos comerciales.",
            icon: icon4
        },
        {
            title: "Financiamiento de órdenes de compra",
            desc: "Simplifique los pagos a proveedores con financiamiento confiable para órdenes de compra nacionales e internacionales.",
            icon: icon5
        },
    ],
}


const Services = () => {

    

    const { language } = useContext(LanguageContext);

    const upcard = uppercards[language] || [];
    const locard = lowercard[language] || [];




    return (
        <div className='bg-dark md:p-[79px] py-[35px] md:mt-0 mt-[64px]' id='services'>
            <div className="grid grid-cols-3 max-w-[1400px] md:mx-auto mx-4 gap-[21px]">
                {/* UpperCards */}
                <div className="grid md:grid-cols-2 grid-cols-1 col-span-3 gap-[21px]">
                    {upcard.map((item, index) => {
                        return (
                            <div key={index} className='bg-darklight md:p-[35px] p-[16px] rounded-lg'>
                                <div className="flex items-center gap-[28px]">
                                    <div>
                                        <img className='w-[47px] h-[47px]' src={item.icon} alt="" />
                                    </div>
                                    <h1 className="md:text-[32px] text-[24px] text-white underline">{item.title}</h1>
                                </div>
                                <p className='text-white mt-[21px] '>{item.desc}</p>
                                <Link to='https://bit.ly/tkambious-sgomez' target='_blank' className='flex item-center text-secondary mt-[31px] md:text-[28px] text-[16px] gap-[10px]'>{language === 'en' ? 'Schedule a Meeting' : 'Programe una llamada'} <img className='md:w-auto md:h-auto w-[11px] h-[18px]' src={arrow} alt="" /></Link>
                                <div className={`overflow-hidden bg-cover`}>
                                    <img className='mt-[38px] w-full object-cover' src={item.img} alt="" />
                                </div>
                            </div>
                        )
                    })}
                </div>
                {/* Lower Cards */}
                {locard.map((item, index) => {
                    return (
                        <div className='bg-darklight p-[26px] rounded-lg md:col-span-1 col-span-3' key={index}>
                            <img src={item.icon} alt="" />
                            <h2 className="md:text-[32px] text-[24px] font-semibold text-white mt-[16px]">{item.title}</h2>
                            <p className="mt-[52px] text-white md:text-[24px] text-[16px]">{item.desc}</p>
                            <Link to='https://bit.ly/tkambious-sgomez' target='_blank' className='flex item-center text-secondary mt-[31px] md:text-[28px] text-[16px] gap-[10px]'>{language === 'en' ? 'Schedule a Meeting' : 'Programe una llamada'} <img className='md:w-auto md:h-auto w-[11px] h-[18px]' src={arrow} alt="" /></Link>

                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Services
