import React from 'react'
import FAQs from '../../common_sections/FAQs'

const CreditFAQs = () => {
    const data = {
        en: [
          {
            name: "What assets can I purchase?",
            desc: "You can finance tangible and intangible assets that are essential for your business operations.",
          },
          {
            name: "How does the 20% down payment work?",
            desc: "The 20% initial payment allows you to secure the asset while spreading the remaining cost over your chosen term.",
          },
          {
            name: "Can I refinance existing assets?",
            desc: "Yes, Infinito Capital offers refinancing options for assets to provide better financial flexibility.",
          },
          {
            name: "Are payments fixed throughout the term?",
            desc: "Yes, all payments are fixed to simplify your budgeting and ensure consistency.",
          },
          {
            name: "How do I apply for credit?",
            desc: "Simply contact us to discuss your business needs, and we’ll guide you through a quick and straightforward application process.",
          },
        ],
        es: [
          {
            name: "¿Qué activos puedo comprar?",
            desc: "Podrás financiar activos tangibles e intangibles que sean esenciales para las operaciones de tu negocio.",
          },
          {
            name: "¿Cómo funciona el pago inicial del 20%?",
            desc: "El pago inicial del 20% le permite asegurar el activo mientras distribuye el costo restante durante el plazo elegido.",
          },
          {
            name: "¿Puedo refinanciar activos existentes?",
            desc: "Sí, Infinito Capital ofrece opciones de refinanciamiento de activos para brindar una mayor flexibilidad financiera.",
          },
          {
            name: "¿Los pagos son fijos durante todo el plazo?",
            desc: "Sí, todos los pagos son fijos para simplificar su presupuesto y garantizar la coherencia.",
          },
          {
            name: "¿Cómo solicito un crédito?",
            desc: "Simplemente contáctenos para analizar las necesidades de su negocio y lo guiaremos a través de un proceso de solicitud rápido y sencillo.",
          },
        ],
       
        
      };
  return (
    <div>
      <FAQs
      data = {data}
      />
    </div>
  )
}

export default CreditFAQs
