import React from 'react'
import MainHero from '../../common_sections/MainHero'

const data = {
    en:{
        title:"Flexible Leasing for Your Business Growth",
        desc:"Discover smarter financing with our leasing services, designed to keep your business running efficiently without heavy upfront costs."
    },
    es:{
        title:"Arrendamiento flexible para el crecimiento de su negocio",
        desc:"Descubra una financiación más inteligente con nuestros servicios de arrendamiento, diseñados para mantener su negocio funcionando de manera eficiente sin grandes costos iniciales."
    },
}
const Hero = () => {
  return (
    <div>
      <MainHero
      info={data}
      />
    </div>
  )
}

export default Hero
