import React, { useContext } from 'react'
import { LanguageContext } from '../../../context/LanguageContext';



const CommonCards = ({entitle,estitle,data}) => {

  const cardData = data;


  const { language } = useContext(LanguageContext);
  const card = cardData[language] || [];
  return (
    <div className='md:my-[144px] my-[64px] max-w-[1440px] md:mx-auto mx-4'>
      <p className="text-center text-secondary md:text-[28px] text-[16px]">{language === 'en'?entitle:estitle}</p>
      {language === 'en' ? (
        <h1 className="text-center text-primary md:text-[56px] text-[26px] leading-[1] font-semibold">Driving sustained business <br /> growth & cashflow</h1>
      ):(
        <h1 className="text-center text-primary md:text-[56px] text-[26px] leading-[1] font-semibold">Impulsar el crecimiento <br /> empresarial y el flujo de caja sostenidos</h1>
      )}
      <div className="grid md:grid-cols-3 grid-cols-1 gap-8 md:mt-[56px] mt-[26px]">
        {card.map((item, index) => {
          return (
            <div className='bg-white p-6 rounded-xl' key={index}>
              <div className="flex items-center gap-6">
                <img src={item.icon} alt="" />
                <h4 className='text-primary font-semibold md:text-[30px] text-[26px]'>{item.title}</h4>
              </div>
              <p className='mt-[20px] md:text-[24px] text-[22px] text-darklight'>{item.desc}</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default CommonCards
