import React, { useEffect } from 'react'
import Hero from '../../components/Sections/Services/RealEstate/Hero'
import FinancialConstraints from '../../components/Sections/Services/RealEstate/FinancialConstraints'
import RealEstateProcess from '../../components/Sections/Services/RealEstate/RealEstateProcess'
import RealEstateFAQs from '../../components/Sections/Services/RealEstate/RealEstateFAQs'
import RealEstateCTA from '../../components/Sections/Services/RealEstate/RealEstateCTA'
import AOS from 'aos';  // Import AOS
import 'aos/dist/aos.css';

const RealEste = () => {
  useEffect(() => {
    // Initialize AOS
    AOS.init({
      duration: 500, 
      once:false,
      mirror: true, 
      offset:150,
    });
  }, []);

  const sections = [<Hero/>,
    <FinancialConstraints/>,
    <RealEstateProcess/>,
    <RealEstateFAQs/>,
    <RealEstateCTA/>]
  return (
    <div>
      {sections.map((item,index)=>{
      return(
        <div key={index} data-aos="fade-up" data-aos-delay={`${index * 100}`}>{item}</div>
      )
    })}
      
    </div>
  )
}

export default RealEste
