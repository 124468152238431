import React from 'react'
import yt from '../../../assets/ytvid.png';

const ClientsSuccess = () => {
  return (
    <div className='md:mb-[144px] flex flex-col max-w-[1400px] md:mx-auto mx-4'>
        <img className='w-full md:order-1 order-2' src={yt} alt="" />
        <div className="flex items-center justify-center md:gap-[80px] gap-[23px] mt-[65px] order-1 md:mb-0 mb-[18px]">
            <div>
                <h1 className="md:text-[64px] text-[18px] font-medium leading-[1] text-primary">99%</h1>
                <h4 className="md:text-[32px] text-[10px] leading-[1] text-primary">Client Success rate</h4>
            </div>
            <div>
                <h1 className="md:text-[64px] text-[18px] font-medium leading-[1] text-primary">$12M+</h1>
                <h4 className="md:text-[32px] text-[10px] leading-[1] text-primary">Ave.Deal value closed</h4>
            </div>
            <div>
                <h1 className="md:text-[64px] text-[18px] font-medium leading-[1] text-primary">4.5/5</h1>
                <h4 className="md:text-[32px] text-[10px] leading-[1] text-primary">Clients ratings</h4>
            </div>
        </div>
    </div>
  )
}

export default ClientsSuccess
