import React from 'react'
import Process from '../../common_sections/Process'
import processimg from '../../../../assets/factoring_process.png'
const data = {
  en: [
      {
          title: "Schedule an Appointment",
          desc: "Connect with our team to discuss your business needs and explore customized factoring solutions."
      },
      {
          title: "Get Your Credit Authorized",
          desc: "Our experts conduct a quick evaluation of your credit to get the process started."
      },
      {
          title: "Secure Your Accounts Receivable",
          desc: "Transfer your invoices to us, ensuring quick processing and peace of mind."
      },
      {
          title: "Receive Funds in Your Bank",
          desc: "Gain immediate liquidity as funds are transferred to your account promptly."
      },
  ],
  es: [
      {
          title: "Programe una cita",
          desc: "Conéctese con nuestro equipo para analizar las necesidades de su negocio y explorar soluciones de factoraje personalizadas."
      },
      {
          title: "Obtenga su crédito autorizado",
          desc: "Nuestros expertos realizan una evaluación rápida de su crédito para iniciar el proceso."
      },
      {
          title: "Asegure sus cuentas por cobrar",
          desc: "Transfiérenos sus facturas, garantizando un procesamiento rápido y tranquilidad."
      },
      {
          title: "Reciba fondos en su banco",
          desc: "Obtenga liquidez inmediata ya que los fondos se transfieren a su cuenta con prontitud."
      },
  ],
}


const OurProcess = () => {
  return (
    <div>
      <Process
      info={data}
      entitle="Factoring with Infinito Capital: A 4-Step Process"
      estitle="Factoring con Infinito Capital: un proceso de 4 pasos"
      img={processimg}
      />
    </div>
  )
}

export default OurProcess
