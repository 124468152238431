import React from 'react'
import CallToAction from '../../common_sections/CallToAction'

const ManagementCTA = () => {
    const data = {
        en:{
            title:"Ready to Optimize Your Financial Operations?",
            desc:"Partner with Infinito Capital for seamless financing and management solutions. Contact us today!"
        },
        es:{
          title:"¿Listo para optimizar sus operaciones financieras?",
          desc:"Asóciese con Infinito Capital para obtener soluciones de gestión y financiación perfectas. ¡Contáctenos hoy!"
        }
    }
  return (
    <div>
      <CallToAction data={data}/>
    </div>
  )
}

export default ManagementCTA
