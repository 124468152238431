import React, { useContext } from 'react'
import logo from '../../../assets/logo.png';
import step1 from '../../../assets/meeting.png'
import step2 from '../../../assets/solutions.png'
import step3 from '../../../assets/grow.png'
import { LanguageContext } from '../../../context/LanguageContext';


const steps = {
    en: [
        {
            icon: step1,
            title: "Schedule a Meeting",
            desc: "Connect with our team to discuss your business needs and financial goals."
        },
        {
            icon: step2,
            title: "Tailored Financial Solutions",
            desc: "We develop financial strategies covering trade, loans, and real estate solutions."
        },
        {
            icon: step3,
            title: "Achieve & Grow",
            desc: "We implement solutions to drive stability and ensure your financial success."
        },
    ],
    es: [
        {
            icon: step1,
            title: "Programe una reunión",
            desc: "Conéctese con nuestro equipo para analizar sus necesidades comerciales y objetivos financieros."
        },
        {
            icon: step2,
            title: "Soluciones financieras a medida",
            desc: "Desarrollamos estrategias financieras que cubren comercio, préstamos y soluciones inmobiliarias."
        },
        {
            icon: step3,
            title: "Lograr y crecer",
            desc: "Implementamos soluciones para impulsar la estabilidad y garantizar su éxito financiero."
        },
    ],
}

const OurProcess = () => {
    const { language } = useContext(LanguageContext);

    const step = steps[language];
    return (
        <div className='bg-[#F5F4F6]'>
            <div className="max-w-[1400px] mx-auto md:py-[144px] py-[64px]" id='howitworks'>
                <div className="flex flex-col items-center">
                    <img src={logo} alt="" />
                    <h1 className="font-bold md:text-[48px] text-[20px] text-dark mt-4">
                        {language === 'en' ? 'Learn More about our process' : 'Conozca más sobre nuestro proceso'}
                    </h1>
                    {language === 'en' ? (
                        <p className='text-dark/70 md:text-[28px] text-[14px] text-center'>
                            Discover how we create tailored solutions to drive <br /> your success at every step.
                        </p>
                    ) : (
                        <p className='text-dark/70 text-[28px] text-center'>
                            Descubra cómo creamos soluciones personalizadas para <br /> impulsar su éxito en cada paso.
                        </p>
                    )}
                    <div className="flex md:flex-row flex-col items-center md:gap-[120px] gap-[32px] mt-[64px]">
                        {step.map((item, index) => {
                            return (
                                <div key={index} className='flex flex-col justify-center items-center md:mx-auto mx-12 text-center'>
                                    <img className='md:w-auto md:h-auto w-[80px] h-[80px]' src={item.icon} alt="" />
                                    <h1 className='font-bold text-dark md:text-[28px] text-[16px] mt-[24px] mb-[8px]'>{item.title}</h1>
                                    <p className='text-dark/70 md:text-[18px] text-[10px]'>{item.desc}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OurProcess
