import React, { useEffect } from 'react'
import Hero from '../../components/Sections/Services/Factoring/Hero'
import Sol from '../../components/Sections/Services/Factoring/Sol'
import LongTerm from '../../components/Sections/Services/Factoring/LongTerm'
import OurProcess from '../../components/Sections/Services/Factoring/OurProcess'
import QuestionsFAQs from '../../components/Sections/Services/Factoring/QuestionsFAQs'
import CTA from '../../components/Sections/Services/Factoring/CTA'
import AOS from 'aos';  // Import AOS
import 'aos/dist/aos.css';

const Factoring = () => {
  useEffect(() => {
    // Initialize AOS
    AOS.init({
      duration: 500, 
      once:false,
      mirror: true, 
      offset:150,
    });
  }, []);

  const sections = [<Hero/>,
    <Sol/>,
    <LongTerm/>,
    <OurProcess/>,
    <QuestionsFAQs/>,
    <CTA/>]
  return (
    <div className=''>
      {sections.map((item,index)=>{
      return(
        <div key={index} data-aos="fade-up" data-aos-delay={`${index * 100}`}>{item}</div>
      )
    })}
      
    </div>
  )
}

export default Factoring
