import React from 'react'
import CommonCards from '../../common_sections/CommonCards'
import i1 from '../../../../assets/cover-expense.png'
import i2 from '../../../../assets/trade-discount.png'
import i3 from '../../../../assets/manage-cash.png'
import i4 from '../../../../assets/fund-payroll.png'
import i5 from '../../../../assets/purchase-equipments.png'
import i6 from '../../../../assets/diversify-risk.png'
const LoansFinancial = () => {
    const data = {
        en: [
            {
              title: "Preserve Liquidity",
              desc: "Keep your business running smoothly while making productive investments.",
              icon: i1,
            },
            {
              title: "Fixed Payments",
              desc: "Fixed monthly payments ensure financial stability.",
              icon: i2,
            },
            {
              title: "Flexible Terms",
              desc: "Choose lease durations of 12, 24, 36, or 48 months to match your business needs.",
              icon: i3,
            },
            {
              title: "Boost Income",
              desc: "Leverage productive assets to increase your revenue while spreading out payments over time.",
              icon: i4,
            },
            {
              title: "Accelerate Growth",
              desc: "Invest in assets that directly enhance your income streams.",
              icon: i5,
            },
            {
              title: "Customizable Terms",
              desc: "Loan terms structured to match your revenue cycles and goals.",
              icon: i6,
            },
          ],
        es: [
            {
              title: "Preservar la liquidez",
              desc: "Mantenga su negocio funcionando sin problemas mientras realiza inversiones productivas.",
              icon: i1,
            },
            {
              title: "Pagos fijos",
              desc: "Los pagos mensuales fijos garantizan la estabilidad financiera.",
              icon: i2,
            },
            {
              title: "Términos flexibles",
              desc: "Elija duraciones de arrendamiento de 12, 24, 36 o 48 meses para satisfacer las necesidades de su negocio.",
              icon: i3,
            },
            {
              title: "Aumentar los ingresos",
              desc: "Aproveche los activos productivos para aumentar sus ingresos mientras distribuye los pagos a lo largo del tiempo.",
              icon: i4,
            },
            {
              title: "Acelerar el crecimiento",
              desc: "Invierta en activos que mejoren directamente sus flujos de ingresos.",
              icon: i5,
            },
            {
              title: "Términos personalizables",
              desc: "Términos de préstamo estructurados para adaptarse a sus ciclos y objetivos de ingresos.",
              icon: i6,
            },
          ],

        
    }
  return (
    <div>
      <CommonCards
      data={data}
      entitle="Leasing Benefit with Infinito Capital"
      estitle="Beneficio de arrendamiento con Infinito Capital"
      />
    </div>
  )
}

export default LoansFinancial
