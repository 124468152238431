import React from 'react'
import Process from '../../common_sections/Process'
import img from '../../../../assets/management_process.png'

const ManagementProcess = () => {
    const data = {
        en: [
            {
                title: "Consultation",
                desc: "Share your business's unique needs with our team."
            },
            {
                title: "Customized Plan",
                desc: "Receive tailored financing and financial management strategies."
            },
            {
                title: "Implementation",
                desc: "Let us handle credit processing, collections, and financial oversight."
            },
            {
                title: "Immesurable Growth",
                desc: "Enjoy improved cash flow and operational efficiency."
            },
        ],
        es: [
            {
                title: "Consulta",
                desc: "Comparta las necesidades únicas de su negocio con nuestro equipo."
            },
            {
                title: "Plan personalizado",
                desc: "Reciba estrategias de financiación y gestión financiera personalizadas."
            },
            {
                title: "Implementación",
                desc: "Permítanos encargarnos del procesamiento de crédito, los cobros y la supervisión financiera."
            },
            {
                title: "Crecimiento inconmensurable",
                desc: "Disfrute de un flujo de caja mejorado y eficiencia operativa"
            },
        ],
        
        
      }
      
  return (
    <div>
      <Process
      info={data}
      entitle="Focus on Business - We’ll handle your finances."
      estitle="Concéntrese en los negocios: nos encargaremos de sus finanzas."
      img={img}
      />
    </div>
  )
}

export default ManagementProcess
