import React, { useContext } from 'react'
import hero from '../../../assets/services_hero.png'
import { Link } from 'react-router-dom'
import { GoArrowRight } from 'react-icons/go'
import { LanguageContext } from '../../../context/LanguageContext'

const MainHero = ({info}) => {
  const {language} = useContext(LanguageContext);

  const data = info;
    const d = data[language];
  return (
    <div className='md:flex container mx-auto'>
      <div className='md:w-[50%] bg-secondary md:pt-[119px] py-[53px] md:px-[80px] px-4'>
        <h1 className="text-dark font-semibold md:text-[50px] text-[24px] leading-[1.2]">{d.title}</h1>
        <p className="md:text-[38px] text-[18px] text-white md:mt-[32px] mt-[22px] leading-[1.3]">{d.desc}</p>
            <Link target='_blank' to='https://bit.ly/tkambious-sgomez' className='duration-300 md:text-[34px] flex items-center w-[60%] mt-5 bg-darklight group hover:bg-primary text-white rounded-lg gap-4 py-2 pl-4 pr-2'>Schedule a Meeting <span><GoArrowRight className='bg-secondary group-hover:bg-darklight md:py-2 py-1 md:text-5xl text-3xl rounded-lg' /></span></Link>
      </div>
      <div className='md:block hidden'>
        <img className='w-full object-cente object-bottom object-scale-up' src={hero} alt="" />
      </div>
    </div>
  )
}

export default MainHero
