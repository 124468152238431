import React from 'react'
import CommonCards from '../../common_sections/CommonCards'
import i1 from '../../../../assets/cover-expense.png'
import i2 from '../../../../assets/trade-discount.png'
import i3 from '../../../../assets/manage-cash.png'
import i4 from '../../../../assets/fund-payroll.png'
import i5 from '../../../../assets/purchase-equipments.png'
import i6 from '../../../../assets/diversify-risk.png'
const Benifits = () => {
    const data = {
        en: [
            {
              title: "Preserve Capital",
              desc: "Free up funds for other critical investments while avoiding large upfront expenses.",
              icon: i1,
            },
            {
              title: "Tax Efficiency",
              desc: "Deduct 100% of lease payments as operational expenses to reduce taxable income",
              icon: i2,
            },
            {
              title: "Flexible Terms",
              desc: "Choose lease durations of 12, 24, 36, or 48 months to match your business needs.",
              icon: i3,
            },
            {
              title: "Mitigate Risks",
              desc: "Reduce the financial risks of asset ownership while keeping your business agile.",
              icon: i4,
            },
            {
              title: "Budget-Friendly Payments",
              desc: "Enjoy peace of mind with consistent monthly payments.",
              icon: i5,
            },
            {
              title: "Access Latest Technology",
              desc: "Protect your business from obsolescence with seamless upgrades.",
              icon: i6,
            },
          ],
        es: [
            {
              title: "Preservar el capital",
              desc: "Libere fondos para otras inversiones críticas y evite grandes gastos iniciales.",
              icon: i1,
            },
            {
              title: "Eficiencia fiscal",
              desc: "Deducir el 100% de los pagos de arrendamiento como gastos operativos para reducir la renta imponible",
              icon: i2,
            },
            {
              title: "Términos flexibles",
              desc: "Elija duraciones de arrendamiento de 12, 24, 36 o 48 meses para satisfacer las necesidades de su negocio",
              icon: i3,
            },
            {
              title: "Mitigar riesgos",
              desc: "Reduzca los riesgos financieros de la propiedad de activos mientras mantiene ágil su negocio.",
              icon: i4,
            },
            {
              title: "Pagos económicos",
              desc: "Disfrute de la tranquilidad con pagos mensuales consistentes.",
              icon: i5,
            },
            {
              title: "Acceda a la última tecnología",
              desc: "Proteja su empresa de la obsolescencia con actualizaciones perfectas.",
              icon: i6,
            },
          ],

        
    }
  return (
    <div>
      <CommonCards
      data={data}
      entitle="Leasing Benefit with Infinito Capital"
      estitle="Beneficio de arrendamiento con Infinito Capital"
      />
    </div>
  )
}

export default Benifits
