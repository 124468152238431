import React from 'react'
import Process from '../../common_sections/Process'
import img from '../../../../assets/loans_process.png'

const LoansProcess = () => {
    const data = {
        en: [
            {
                title: "Eligibility Check",
                desc: "We assess your needs and tailor terms to suit your cash flow."
            },
            {
                title: "Select Terms",
                desc: "Options include 6, 12, 18, or 24 months for predictable financial planning."
            },
            {
                title: "Asset Financing",
                desc: "Use funds for productive, tangible, or intangible investments."
            },
            {
                title: "Fund Disbursement",
                desc: "Immediate access to financing after finalizing agreements."
            },
        ],
        es: [
            {
                title: "Verificación de elegibilidad",
                desc: "Evaluamos sus necesidades y adaptamos los términos para adaptarnos a su flujo de caja."
            },
            {
                title: "Seleccionar términos",
                desc: "Las opciones incluyen 6, 12, 18 o 24 meses para una planificación financiera predecible."
            },
            {
                title: "Financiamiento de Activos",
                desc: "Usar fondos para inversiones productivas, tangibles o intangibles."
            },
            {
                title: "Desembolso de fondos",
                desc: "Acceso inmediato a financiación tras finalizar acuerdos."
            },
        ],
        
      }
      
  return (
    <div>
      <Process
      info={data}
      entitle="No more Financial constraint- Scale Faster"
      estitle="No más restricciones financieras: escale más rápido"
      img={img}
      />
    </div>
  )
}

export default LoansProcess
