import React from 'react'
import FAQs from '../../common_sections/FAQs'
const data = {
    en: [
      {
        name: "What assets can I lease?",
        desc: "We offer leasing for office equipment, production machinery, and advanced technology.",
      },
      {
        name: "What happens if I no longer need the leased asset?",
        desc: "Return it, renew the lease, or opt for an upgrade at your convenience.",
      },
      {
        name: "How is leasing different from a loan?",
        desc: "Leasing allows you to use assets without owning them, reducing risk and financial strain.",
      },
      {
        name: "Can I upgrade my leased equipment?",
        desc: "Yes, our flexible contracts support technological upgrades to keep you competitive.",
      },
      {
        name: "Are lease payments fixed?",
        desc: "Yes, payments are fixed with no indexations, ensuring predictability.",
      },
    ],
    es: [
      {
        name: "¿Qué activos puedo arrendar?",
        desc: "Ofrecemos arrendamiento de equipos de oficina, maquinaria de producción y tecnología avanzada.",
      },
      {
        name: "¿Qué pasa si ya no necesito el bien arrendado?",
        desc: "Devuélvelo, renueva el contrato de arrendamiento u opta por una actualización según tu conveniencia.",
      },
      {
        name: "¿En qué se diferencia el arrendamiento de un préstamo?",
        desc: "El arrendamiento le permite utilizar activos sin poseerlos, lo que reduce el riesgo y la tensión financiera.",
      },
      {
        name: "¿Puedo actualizar mi equipo arrendado?",
        desc: "Sí, nuestros contratos flexibles respaldan actualizaciones tecnológicas para mantenerlo competitivo.",
      },
      {
        name: "¿Los pagos del arrendamiento son fijos?",
        desc: "Sí, los pagos son fijos sin indexaciones, lo que garantiza la previsibilidad.",
      },
    ],
    
  };


const LeaseFAQs = () => {
  return (
    <div>
      <FAQs
      data = {data}
      />
    </div>
  )
}

export default LeaseFAQs
