import React, { useEffect } from 'react'
import Hero from '../../components/Sections/Services/FinancialMangament/Hero'
import MangamentConstraints from '../../components/Sections/Services/FinancialMangament/MangamentConstraints'
import ManagementProcess from '../../components/Sections/Services/FinancialMangament/ManagementProcess'
import ManagementFAQs from '../../components/Sections/Services/FinancialMangament/ManagementFAQs'
import ManagementCTA from '../../components/Sections/Services/FinancialMangament/ManagementCTA'
import AOS from 'aos';  // Import AOS
import 'aos/dist/aos.css';
const FinancialManagement = () => {
  useEffect(() => {
    // Initialize AOS
    AOS.init({
      duration: 500, 
      once:false,
      mirror: true, 
      offset:150,
    });
  }, []);

  const sections = [<Hero/>,
    <MangamentConstraints/>,
    <ManagementProcess/>,
    <ManagementFAQs/>,
    <ManagementCTA/>]
  return (
    <div>
      {sections.map((item,index)=>{
      return(
        <div key={index} data-aos="fade-up" data-aos-delay={`${index * 100}`}>{item}</div>
      )
    })}
      
    </div>
  )
}

export default FinancialManagement
