import React, { useEffect } from 'react'
import Hero from '../../components/Sections/Services/Lease/Hero'
import Benifits from '../../components/Sections/Services/Lease/Benifits'
import LeaseProcess from '../../components/Sections/Services/Lease/LeaseProcess'
import LeaseFAQs from '../../components/Sections/Services/Lease/LeaseFAQs'
import LeaseCTA from '../../components/Sections/Services/Lease/LeaseCTA'
import AOS from 'aos';  // Import AOS
import 'aos/dist/aos.css';
const Lease = () => {
  useEffect(() => {
    // Initialize AOS
    AOS.init({
      duration: 500, 
      once:false,
      mirror: true, 
      offset:150,
    });
  }, []);
  const sections = [<Hero/>,
    <Benifits/>,
    <LeaseProcess/>,
    <LeaseFAQs/>,
    <LeaseCTA/>]
  return (
    <div>
       {sections.map((item,index)=>{
      return(
        <div key={index} data-aos="fade-up" data-aos-delay={`${index * 100}`}>{item}</div>
      )
    })}
      
    </div>
  )
}

export default Lease
