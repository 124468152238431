import React from 'react'
import MainHero from '../../common_sections/MainHero'

const Hero = () => {
    const data = {
        en:{
            title:"Unlock Capital from Real Estate for Business Growth",
            desc:"Transform your real estate investments into working capital  assets to fuel growth, stabilize operations, and unlock your business's full potential."
        },
        es:{
            title:"Libere capital de bienes raíces para el crecimiento empresarial",
            desc:"Transforme sus inversiones inmobiliarias en activos de capital de trabajo para impulsar el crecimiento, estabilizar las operaciones y desbloquear todo el potencial de su negocio."
        },
    }
  return (
    <div>
      <MainHero
      info={data}
      />
    </div>
  )
}

export default Hero
