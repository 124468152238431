import React, { useEffect } from 'react'
import Hero from '../../components/Sections/Services/InventoryFinance/Hero'
import FinancialConstraints from '../../components/Sections/Services/InventoryFinance/FinancialConstraints'
import InventoryProcess from '../../components/Sections/Services/InventoryFinance/InventoryProcess'
import InventoryFAQs from '../../components/Sections/Services/InventoryFinance/InventoryFAQs'
import InventoryCTA from '../../components/Sections/Services/InventoryFinance/InventoryCTA'
import AOS from 'aos';  // Import AOS
import 'aos/dist/aos.css';
const InventoryFinance = () => {

  useEffect (() => {
    // Initialize AOS
    AOS.init({
      duration: 500, 
      once:false,
      mirror: true, 
      offset:150,
    });
  }, []);
  const sections = [<Hero/>,
    <FinancialConstraints/>,
    <InventoryProcess/>,
    <InventoryFAQs/>,
    <InventoryCTA/>]
  return (
    <div>
      {sections.map((item,index)=>{
      return(
        <div key={index} data-aos="fade-up" data-aos-delay={`${index * 100}`}>{item}</div>
      )
    })}
      
    </div>
  )
}

export default InventoryFinance
