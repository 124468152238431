import React, { useContext } from 'react'
import { LanguageContext } from '../../../context/LanguageContext'
import { MdPhone, MdMail, MdLocationOn } from "react-icons/md";

const cards = {
    en: [
        {
            name: "Phone",
            icon: <MdPhone/>,
            desc: ["JRZ: (656) 438-5025", "JRZ: (656) 611-3764", "US: (915) 288-3049"],
        },
        {
            name: "Email",
            icon: <MdMail/>,
            desc: ["Info@infinitocapital.com"],
        },
        {
            name: "Head Office",
            icon: <MdLocationOn/>,
            desc: ["Blvd. Manuel Gomez Morin 7045 Int. 204B,2nd Floor of the Condak Building"],
        },
    ],
    es: [
        {
            name: "Teléfono",
            icon: <MdPhone/>,
            desc: ["JRZ: (656) 438-5025", "JRZ: (656) 611-3764", "US: (915) 288-3049"],
        },
        {
            name: "Email",
            icon: <MdMail/>,
            desc: ["Info@infinitocapital.com"],
        },
        {
            name: "Oficina central",
            icon: <MdLocationOn/>,
            desc: ["Blvd. Manuel Gomez Morin 7045 Int. 204B,2nd Floor of the Condak Building"],
        },
    ],
}

const Contact = () => {
    const { language } = useContext(LanguageContext);
    const card = cards[language] || [];
    return (
        <div className='bg-[#F5F4F6] md:py-[144px] py-[64px]' id='contact'>
            <div className="flex flex-col items-center text-center">
                <h4 className="text-secondary md:text-[28px] text-[12px]">
                    {language === 'en' ? "Contact Us" : "Contacto"}
                </h4>
                <h1 className="md:text-[48px] text-[20px] font-bold text-dark">
                    {language === 'en' ? "Get in touch with us" : "Ponte en contacto con nosotros"}
                </h1>
                <p className="md:text-[28px] text-[14px] text-dark/70 md:w-[560px]">
                    {language === 'en' ? "Discover how we create tailored solutions to drive your success at every step." : "Descubra cómo creamos soluciones personalizadas para impulsar su éxito en cada paso."}
                </p>
                <div className='grid md:grid-cols-3 grid-cols-1  md:gap-[64px] gap-[32px] md:mt-[64px] mt-[32px]'>
                    {card.map((i,index)=>{
                        return(
                            <div key={index} className='bg-primary p-[37px] md:w-auto w-[250px] rounded-lg flex flex-col items-center text-white'>
                                <div className='md:text-6xl text-2xl'>{i.icon}</div>
                                <h3 className="md:text-[32px] text-[16px] font-bold">{i.name}</h3>
                                <div className='md:w-[265px] md:text-[20px] text-[13px]'>
                                    {(i.desc || []).map((item,index)=>{
                                        return(
                                            <li className='list-none text-center' key={index}>{item}</li>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default Contact
