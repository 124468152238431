import React, { useContext, useState } from 'react'
import { LanguageContext } from '../../../context/LanguageContext'
import { GoArrowRight } from 'react-icons/go';
import { Link } from 'react-router-dom';
import { IoMdArrowDropright, IoMdArrowDropdown } from "react-icons/io";
import { motion } from 'framer-motion'
const FAQs = ({data}) => {
  const [openIndex, setOpenIndex] = useState(null); // Track which FAQ is open

  const { language } = useContext(LanguageContext);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index); // Toggle specific FAQ
  };

  const faqs = data;

  const faq = faqs[language] || [];
  return (
    <div className='max-w-[1400px] md:mx-auto mx-4 md:mb-[144px] mb-[64px]'>
      <div className="flex md:flex-row flex-col items-center">
        <div>
          <h6 className="md:text-[28px] text-[16px] text-secondary">{language === 'en' ? "Frequently asked questions" : "Preguntas frecuentes"}</h6>
          <h1 className="md:text-[80px] text-[30px] text-primary font-semibold leading-[1.2]">{language === 'en' ? "We’re Here to Help" : "Estamos aquí para ayudar"}</h1>
          <Link className='duration-300 md:text-[34px] flex items-center md:w-[40%] mt-5 bg-darklight group hover:bg-primary text-white rounded-lg gap-4 py-2 pl-4 pr-2'>{language === 'en' ? 'Contact Us' : 'Contacto'} <span><GoArrowRight className='bg-secondary group-hover:bg-darklight md:py-2 py-1 md:text-5xl text-3xl rounded-lg' /></span></Link>
        </div>

        <div className='md:w-[50%]'>
          <div className="md:mt-[72px] mt-[33px]">
            {faq.map((i, index) => (
              <div key={index} className='border-b py-4 mt-3 bg-white px-4 rounded-xl'>
                <div
                  className="flex justify-between items-center cursor-pointer mb-4"
                  onClick={() => toggleFAQ(index)}
                >
                  <h3 className="md:text-[28px] text-[18px] text-black font-medium">{i.name}</h3>
                  <motion.div
                    initial={{ rotate: 90 }}
                    animate={{ rotate: openIndex === index ? 180 : 90 }} // Rotate based on openIndex
                    transition={{ duration: 0.3 }}
                    className="md:text-4xl"
                  >
                    {openIndex === index ? <IoMdArrowDropdown /> : <IoMdArrowDropright />}
                  </motion.div>

                </div>
                <motion.div
                  initial={{ height: 0 }}
                  animate={{ height: openIndex === index ? 'auto' : 0 }}
                  transition={{ duration: 0.3 }}
                  className="md:text-[20px] text-[14px] text-black/70 overflow-hidden"
                >
                  {i.desc}
                </motion.div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FAQs
