import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { BsArrowRight } from 'react-icons/bs'
import { LanguageContext } from '../context/LanguageContext'
import logo from '../assets/logo.png'
import { BsTwitterX,BsLinkedin ,BsInstagram ,BsYoutube  } from "react-icons/bs";
const Footer = () => {
  const { language } = useContext(LanguageContext);
  return (
    <div className='relative'>
      <div className='md:block absolute hidden top-[-90px] left-0 right-0 max-w-[1400px] mx-auto bg-white px-16 py-12 rounded-lg myshadow mb-9'>
        <div className="flex items-center justify-between">
          <div>
            <h1 className="text-[32px] text-dark font-semibold">
              {language === 'en' ? "Got Questions? We're Here to Help!" : "¿Tienes preguntas? ¡Estamos aquí para ayudar!"}
            </h1>
            <p className='text-[24px] text-dark/70'>
              {language === 'en' ? "Reach out to us today and we'll be happy to assist with any queries you have." : "Comuníquese con nosotros hoy y estaremos encantados de ayudarle con cualquier consulta que tenga."}
            </p>
          </div>
          <div>
            <Link to='/contact' className='text-primary flex items-center gap-2 font-medium text-[24px]'>
              {language === 'en' ? "Contact Us" : "Contacto"} <BsArrowRight /></Link>
          </div>
        </div>
      </div>
      <div className="bg-[#0E0F15] md:pt-[147px] p-6 ">
        <div className="grid md:grid-cols-3 grid-cols-1 max-w-[1400px] mx-auto text-white">
          <di className="md:mt-0 mt-8">
            <img src={logo} alt="" className='mb-4' />
            <p>Blvd. Manuel Gomez Morin 7045, 2nd Floor,<br /> Condak Building</p>
            <p>Col. Rincones de Santa Rita, Ciudad Juarez,<br /> Chihuahua</p>
            <div className='list-none mt-6'>
              <li>MX: +52 656 438.5025</li>
              <li>US: +1 915 955.2646</li>
              <li className='underline'>Info@infinitocapital.com .</li>
            </div>
          </di>
          <div className="md:mt-0 mt-8">
            <h1 className='uppercase text-[24px] font-semibold'>Quick Links</h1>
            <ul className='flex flex-col gap-2 mt-8 text-[20px]'>
              <li><Link className='hover:text-secondary'>Home</Link></li>
              <li><Link className='hover:text-secondary'>What we do</Link></li>
              <li><Link className='hover:text-secondary'>Services</Link></li>
              <li><Link className='hover:text-secondary'>How it works</Link></li>
              <li><Link className='hover:text-secondary'>Office Locator</Link></li>
              <li><Link className='hover:text-secondary'>Terms of use</Link></li>
            </ul>
          </div>
          <div className="md:mt-0 mt-8">
            <h1 className='uppercase text-[24px] font-semibold'>Our Newletter</h1>
            <p className='text-[20px] text-white/80'>Stay updated with the latest news, tips, and offers.</p>
            <div className='bg-white rounded-full py-2 px-6 mt-8 flex items-center justify-between'>
              <input type="text" className='outline-none text-darklight' placeholder='johndoe@gmail.com'/>
              <BsArrowRight className='bg-secondary rounded-full text-5xl p-2'/>
            </div>
          </div>
        </div>
        {/*  */}
        <div className='flex md:flex-row flex-col gap-6 md:justify-between items-center text-white mt-28 max-w-[1400px] md:mx-auto mx-4'>
          <div className='md:order-1 order-2'>Copyright 2024 | Infinito Capital | All Rights Reserved | Developed by <Link className='underline text-secondary' to={'https://www.linkedin.com/in/muhammad-abu-bakar-982007338/'} target='_blank'>Muhammad Abu Bakar</Link></div>
          <div className='flex gap-6 text-2xl md:order-2 order-1'>
            <Link to={process.env.REACT_APP_TWITTER_LINK} target='_blank'><BsTwitterX/></Link>
            <Link to={process.env.REACT_APP_INSTAGRAM_LINK} target='_blank'><BsInstagram/></Link>
            <Link to={process.env.REACT_APP_LINKEDIN_LINK} target='_blank'><BsLinkedin/></Link>
            <Link to={process.env.REACT_APP_YOUTUBE_LINK} target='_blank'><BsYoutube/></Link>
          </div>
        </div>
      </div>
      <div className='bg-secondary p-1'></div>
    </div>
  )
}

export default Footer
