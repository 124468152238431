import React from 'react'
import MainHero from '../../common_sections/MainHero'

const data = {
    en:{
        title:"Focus on Growth - Leave your financial management to us.",
        desc:"Focus on growing your business while we handle financing, credit management, collections, and administration."
    },
    es:{
      title:"Céntrese en el crecimiento: déjenos su gestión financiera a nosotros.",
      desc:"Concéntrese en hacer crecer su negocio mientras nosotros nos encargamos del financiamiento, la gestión de crédito, los cobros y la administración"
    },
}
const Hero = () => {
  return (
    <div>
      <MainHero
      info={data}
      />
    </div>
  )
}

export default Hero
