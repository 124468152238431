import React from 'react'
import MainHero from '../../common_sections/MainHero'

const Hero = () => {
    const data = {
        en:{
            title:"Maximize Growth with Flexible Inventory Financing",
            desc:"Tailored financing solutions for your inventory needs, reducing costs and enhancing cash flow while driving your business forward."
        },
        es:{
            title:"Maximice el crecimiento con financiación de inventario flexible",
            desc:"Soluciones de financiación personalizadas para sus necesidades de inventario, reduciendo costos y mejorando el flujo de caja mientras impulsa su negocio."
        },
    }
  return (
    <div>
      <MainHero
      info={data}
      />
    </div>
  )
}

export default Hero
