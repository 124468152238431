import React from 'react'
import FAQs from '../../common_sections/FAQs'

const InventoryFAQs = () => {
    const data = {
        en: [
          {
            name: "What is inventory financing?",
            desc: "It's a funding option that uses your inventory as collateral for working capital.",
          },
          {
            name: "How is it different from a loan?",
            desc: "Unlike traditional loans, inventory financing doesn't require collateral like real estate or personal guarantees.",
          },
          {
            name: "Who can use inventory financing?",
            desc: "Retailers, manufacturers, and import/export businesses needing inventory funding.",
          },
          {
            name: "What does it cover?",
            desc: "Bulk purchases, logistics, and related operational costs.",
          },
          {
            name: "How do repayments work?",
            desc: "Buy back inventory at the original price plus a pre-agreed cost.",
          },
        ],
        es: [
          {
            name: "¿Qué es la financiación de inventarios?",
            desc: "Es una opción de financiación que utiliza su inventario como garantía para el capital de trabajo.",
          },
          {
            name: "¿En qué se diferencia de un préstamo?",
            desc: "A diferencia de los préstamos tradicionales, la financiación de inventarios no requiere garantías como bienes inmuebles o garantías personales.",
          },
          {
            name: "¿Quién puede utilizar la financiación de inventarios?",
            desc: "Minoristas, fabricantes y empresas de importación/exportación que necesitan financiación de inventario.",
          },
          {
            name: "¿Qué cubre?",
            desc: "Compras a granel, logística y costos operativos relacionados.",
          },
          {
            name: "¿Cómo funcionan los reembolsos?",
            desc: "Recompra de inventario al precio original más un costo previamente acordado.",
          },
        ],

        
      };
  return (
    <div>
      <FAQs
      data = {data}
      />
    </div>
  )
}

export default InventoryFAQs
