import React from 'react'
import CallToAction from '../../common_sections/CallToAction'

const InventoryCTA = () => {
    const data = {
        en:{
            title:"Ready to Equip Your Business for Success?",
            desc:"Revolutionize your inventory financing strategy. Partner with Infinito Capital today and unlock your growth potential."
        },
        es:{
            title:"¿Listo para equipar su negocio para el éxito?",
            desc:"Revolucione su estrategia de financiación de inventario. Asóciese con Infinito Capital hoy y libere su potencial de crecimiento."
        }
    }
  return (
    <div>
      <CallToAction data={data}/>
    </div>
  )
}

export default InventoryCTA
