import React from 'react'
import FAQs from '../../common_sections/FAQs'

const data = {
  en: [
    {
      name: "What is factoring?",
      desc: "Factoring is a financing solution where businesses sell their accounts receivable for immediate cash, helping maintain cash flow without waiting for clients to pay.",
    },
    {
      name: "Who should use factoring?",
      desc: "Businesses of all sizes, including SMEs, service providers, and manufacturers, can benefit from factoring to manage cash flow and grow their operations.",
    },
    {
      name: "How long does it take to access funds?",
      desc: "Typically, you’ll receive the initial funding within a few days of invoice approval.",
    },
    {
      name: "What percentage of invoice value is advanced?",
      desc: "Up to 80% of the invoice value is advanced, with the remainder paid after collection (minus commission).",
    },
    {
      name: "Does factoring affect my credit?",
      desc: "No, factoring does not add debt to your balance sheet. It’s a sale of receivables, not a loan.",
    },
  ],
  es: [
    {
      name: "¿Qué es el factoraje?",
      desc: "El factoraje es una solución de financiación en la que las empresas venden sus cuentas por cobrar para obtener efectivo inmediato, lo que ayuda a mantener el flujo de caja sin esperar a que los clientes paguen.",
    },
    {
      name: "¿Quién debería utilizar el factoring?",
      desc: "Empresas de todos los tamaños, incluidas pymes, proveedores de servicios y fabricantes, pueden beneficiarse del factoring para gestionar el flujo de caja y hacer crecer sus operaciones.",
    },
    {
      name: "¿Cuánto tiempo lleva acceder a los fondos?",
      desc: "Normalmente, recibirá la financiación inicial unos días después de la aprobación de la factura.",
    },
    {
      name: "¿Qué porcentaje del valor de la factura se adelanta?",
      desc: "Se adelanta hasta el 80% del valor de la factura y el resto se paga después del cobro (menos comisión).",
    },
    {
      name: "¿El factoring afecta mi crédito?",
      desc: "No, el factoring no agrega deuda a su balance. Es una venta de cuentas por cobrar, no un préstamo.",
    },
  ],
};

const QuestionsFAQs = () => {
  return (
    <div>
      <FAQs
      data={data}
      />
    </div>
  )
}

export default QuestionsFAQs
