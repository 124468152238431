import React from 'react'
import Process from '../../common_sections/Process'
import img from '../../../../assets/lease_process.png'
const data = {
  en: [
      {
          title: "Select Your Assets",
          desc: "Choose the equipment or property you need."
      },
      {
          title: "Sign a Flexible Lease Agreement",
          desc: "Tailored to your operational and financial goals."
      },
      {
          title: "Utilize the Asset",
          desc: "Begin using the leased items to scale your business operations."
      },
      {
          title: "Decide at Lease End",
          desc: "Opt to renew, return, or purchase the asset at the end of the lease term."
      },
  ],
  es: [
      {
          title: "Seleccione sus activos",
          desc: "Elija el equipo o propiedad que necesita."
      },
      {
          title: "Firmar un contrato de arrendamiento flexible",
          desc: "Adaptado a sus objetivos operativos y financieros."
      },
      {
          title: "Utilice el activo",
          desc: "Comience a utilizar los artículos arrendados para ampliar sus operaciones comerciales."
      },
      {
          title: "Decidir al finalizar el arrendamiento",
          desc: "Optar por renovar, devolver o comprar el activo al final del plazo del arrendamiento."
      },
  ],
  
}


const LeaseProcess = () => {
  return (
    <div>
      <Process
      info={data}
      entitle="Flexible Leasing Process with Infinito Capital"
      estitle="Proceso de arrendamiento flexible con Infinito Capital"
      img={img}
      />
    </div>
  )
}

export default LeaseProcess
