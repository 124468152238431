import React from 'react'
import CallToAction from '../../common_sections/CallToAction'

const CTA = () => {

    const data = {
        en:{
            title:"Ready to improve your business cashflow.",
            desc:"Boost your revenue and steady cash flow. Schedule an appointment with Infinito Capital to explore growth-driven financial solutions."
        },
        es:{
            title:"Listo para mejorar el flujo de caja de su negocio.",
            desc:"Aumente sus ingresos y su flujo de caja constante. Programe una cita con Infinito Capital para explorar soluciones financieras impulsadas por el crecimiento."
        }
    }
  return (
    <div>
      <CallToAction data={data}/>
    </div>
  )
}

export default CTA
