import React, { useEffect } from 'react'
import Hero from '../../components/Sections/Services/CapitalLoans/Hero'
import LoansFinancial from '../../components/Sections/Services/CapitalLoans/LoansFinancial'
import AOS from 'aos';  // Import AOS
import 'aos/dist/aos.css';
import LoansProcess from '../../components/Sections/Services/CapitalLoans/LoansProcess';
import LoansFAQs from '../../components/Sections/Services/CapitalLoans/LoansFAQs';
import LoansCTA from '../../components/Sections/Services/CapitalLoans/LoansCTA';
const CapitalLoans = () => {
  useEffect(() => {
    // Initialize AOS
    AOS.init({
      duration: 500, 
      once:false,
      mirror: true, 
      offset:150,
    });
  }, []);

  const sections = [<Hero/>,<LoansFinancial/>,<LoansProcess/>,<LoansFAQs/>,<LoansCTA/>]
  return (
    <div>
      {sections.map((item,index)=>{
      return(
        <div key={index} data-aos="fade-up" data-aos-delay={`${index * 100}`}>{item}</div>
      )
    })}
      
    </div>
  )
}

export default CapitalLoans
