import React from 'react'
import i1 from '../../../../assets/cover-expense.png'
import i2 from '../../../../assets/trade-discount.png'
import i3 from '../../../../assets/manage-cash.png'
import i4 from '../../../../assets/fund-payroll.png'
import i5 from '../../../../assets/purchase-equipments.png'
import i6 from '../../../../assets/diversify-risk.png'
import CommonCards from '../../common_sections/CommonCards'


const LongTerm = () => {
    const data = {
        en: [
            {
              title: "Cover Expenses",
              desc: "Secure the liquidity needed to keep your business running smoothly without delays.",
              icon: i1,
            },
            {
              title: "Trade Discounts",
              desc: "Use immediate cash flow to capitalize on supplier discounts, reducing overall costs.",
              icon: i2,
            },
            {
              title: "Manage Cash Flow",
              desc: "Bridge gaps in cash flow during seasonal or unexpected revenue slowdowns.",
              icon: i3,
            },
            {
              title: "Fund Payroll",
              desc: "Ensure employees are paid on time, maintaining trust and operational stability.",
              icon: i4,
            },
            {
              title: "Purchase Equipment",
              desc: "Invest in essential tools or machinery without compromising your working capital.",
              icon: i5,
            },
            {
              title: "Diversify Risk",
              desc: "Spread financial risks by leveraging accounts receivable as a flexible funding solution.",
              icon: i6,
            },
          ],
        es: [
            {
              title: "Cubrir gastos",
              desc: "Asegure la liquidez necesaria para mantener su negocio funcionando sin problemas y sin demoras.",
              icon: i1,
            },
            {
              title: "Descuentos comerciales",
              desc: "Utilice el flujo de caja inmediato para capitalizar los descuentos de los proveedores, reduciendo los costos generales.",
              icon: i2,
            },
            {
              title: "Administrar el flujo de caja",
              desc: "Cerrar las brechas en el flujo de caja durante desaceleraciones estacionales o inesperadas de los ingresos.",
              icon: i3,
            },
            {
              title: "Fondo de nómina",
              desc: "Asegúrese de que los empleados reciban su pago a tiempo, manteniendo la confianza y la estabilidad operativa.",
              icon: i4,
            },
            {
              title: "Compra de equipo",
              desc: "Invierta en herramientas o maquinaria esenciales sin comprometer su capital de trabajo.",
              icon: i5,
            },
            {
              title: "Diversificar el riesgo",
              desc: "Distribuya los riesgos financieros aprovechando las cuentas por cobrar como una solución de financiación flexible.",
              icon: i6,
            },
          ],
        
    }
  return (
    <div>
      <CommonCards
      data={data}
      entitle="Long-Term Partnership"
      estitle="Asociación a largo plazo"
      />
    </div>
  )
}

export default LongTerm
