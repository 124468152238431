import React from 'react'
import CallToAction from '../../common_sections/CallToAction'

const RealEstateCTA = () => {
    const data = {
        en:{
            title:"Take Control of Your Business's Future Today",
            desc:"Discover how Infinito Capital can transform your real estate into a growth engine. Contact us now to unlock liquidity and reinvest in your company’s success."
        },
        es:{
          title:"Tome el control del futuro de su negocio hoy",
          desc:"Descubra cómo Infinito Capital puede transformar su sector inmobiliario en un motor de crecimiento. Contáctenos ahora para liberar liquidez y reinvertir en el éxito de su empresa"
        }
    }
  return (
    <div>
      <CallToAction data={data}/>
    </div>
  )
}

export default RealEstateCTA
