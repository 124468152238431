import React, { useEffect } from 'react'
import Hero from '../components/Sections/LandingPageSections/Hero'
import StandOut from '../components/Sections/LandingPageSections/StandOut'
import ClientsSuccess from '../components/Sections/LandingPageSections/ClientsSuccess'
import Services from '../components/Sections/LandingPageSections/Services'
import OurProcess from '../components/Sections/LandingPageSections/OurProcess'
import Help from '../components/Sections/LandingPageSections/Help'
import Contact from '../components/Sections/LandingPageSections/Contact'
import GoogleMapS from '../components/Sections/LandingPageSections/GoogleMap'
import ContactForm from '../components/Sections/LandingPageSections/ContactForm'
import AOS from 'aos';  // Import AOS
import 'aos/dist/aos.css';

const LandingPage = () => {
  useEffect(() => {
    // Initialize AOS
    AOS.init({
      duration: 500, 
      once:false,
      mirror: true, 
      offset:150,
    });
  }, []);

  const sections = [<Hero/>,<StandOut/>,<ClientsSuccess/>,<Services/>,<OurProcess/>,<Help/>,<Contact/>,<GoogleMapS/>,<ContactForm/>]
  return (
    <>
    {sections.map((item,index)=>{
      return(
        <div key={index} data-aos="fade-up" data-aos-delay={`${index * 100}`}>{item}</div>
      )
    })}
  </>
  )
}

export default LandingPage
