import React from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import LandingPage from './pages/LandingPage'
import Header from './components/Header'
import Footer from './components/Footer'
import { LanguageProvider } from './context/LanguageContext'
import Factoring from './pages/Services/Factoring'
import Lease from './pages/Services/Lease'
import Credit from './pages/Services/Credit'
import CapitalLoans from './pages/Services/CapitalLoans'
import InventoryFinance from './pages/Services/InventoryFinance'
import RealEste from './pages/Services/RealEste'
import FinancialManagement from './pages/Services/FinancialManagement'
import ScrollToTop from './components/ScrollToTop'

const App = () => {
  const servicesRoutes = [
    {
      name:'factoring',
      compo:<Factoring/>
    },
    {
      name:'lease',
      compo:<Lease/>
    },
    {
      name:'credit',
      compo:<Credit/>
    },
    {
      name:'capital-loans',
      compo:<CapitalLoans/>
    },
    {
      name:'inventory-financing',
      compo:<InventoryFinance/>
    },
    {
      name:'real-estate',
      compo:<RealEste/>
    },
    {
      name:'financial-management',
      compo:<FinancialManagement/>
    },
  ];
  return (
    <>
        <BrowserRouter>
          <LanguageProvider>
            <Header />
            <ScrollToTop/>
            <div className='pt-[4rem]'>
            <Routes>
            <Route path="/" element={<LandingPage />} />
            {servicesRoutes.map((service) => (
              <Route
                key={service}
                path={`/services/${service.name}`}
                element={service.compo} // Replace with specific components if needed
              />
            ))}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
            </div>
            <Footer />
          </LanguageProvider>
        </BrowserRouter>
    </>
  )
}

export default App
