import React from 'react'
import CommonCards from '../../common_sections/CommonCards'
import i1 from '../../../../assets/cover-expense.png'
import i2 from '../../../../assets/trade-discount.png'
import i3 from '../../../../assets/manage-cash.png'
import i4 from '../../../../assets/fund-payroll.png'
import i5 from '../../../../assets/purchase-equipments.png'
import i6 from '../../../../assets/diversify-risk.png'
const FinancialConstraints = () => {

    const data = {
        en: [
            {
              title: "Acquire Essential Assets",
              desc: "Finance the purchase of equipment while maintaining liquidity.",
              icon: i1,
            },
            {
              title: "Fixed Payments",
              desc: "Predictable payments simplify budgeting and planning.",
              icon: i2,
            },
            {
              title: "Flexible Terms",
              desc: "Choose lease durations of 12, 24, 36, or 48 months to match your business needs.",
              icon: i3,
            },
            {
              title: "Boost Income",
              desc: "Leverage productive assets to increase your revenue while spreading out payments over time.",
              icon: i4,
            },
            {
              title: "Upgrade Assets",
              desc: "Upgrade or refinance your existing assets to maintain a competitive edge.",
              icon: i5,
            },
            {
              title: "Simplify Cash Flow Planning",
              desc: "Predictable fixed payments make it easier to manage your budget and avoid sudden cash flow disruptions.",
              icon: i6,
            },
          ],
        es: [
            {
              title: "Adquirir activos esenciales",
              desc: "Financiar la compra de equipos manteniendo la liquidez.",
              icon: i1,
            },
            {
              title: "Pagos fijos",
              desc: "Los pagos predecibles simplifican la elaboración de presupuestos y la planificación.",
              icon: i2,
            },
            {
              title: "Términos flexibles",
              desc: "Elija duraciones de arrendamiento de 12, 24, 36 o 48 meses para satisfacer las necesidades de su negocio.",
              icon: i3,
            },
            {
              title: "Aumentar los ingresos",
              desc: "Aproveche los activos productivos para aumentar sus ingresos mientras distribuye los pagos a lo largo del tiempo.",
              icon: i4,
            },
            {
              title: "Actualizar activos",
              desc: "Actualice o refinancie sus activos existentes para mantener una ventaja competitiva.",
              icon: i5,
            },
            {
              title: "Simplifique la planificación del flujo de caja",
              desc: "Los pagos fijos predecibles facilitan la gestión de su presupuesto y evitan interrupciones repentinas en el flujo de caja.",
              icon: i6,
            },
          ],        
    }

  return (
    <div>
      <CommonCards
      data={data}
      entitle="Zero-Financial Constraints "
      estitle="Restricciones financieras cero"
      />
    </div>
  )
}

export default FinancialConstraints
