import React, { useContext } from 'react'
import bg from '../../../assets/herobg.png'
import { Link } from 'react-router-dom'
import { GoArrowRight } from "react-icons/go";
import { LanguageContext } from '../../../context/LanguageContext'


const Hero = () => {
    const { language } = useContext(LanguageContext)
    return (
        <div style={{ backgroundImage: `url(${bg})` }} className='md:py-0 py-16 md:h-[92vh] bg-cover flex justify-center items-center flex-col'>
            {language === 'en' ? (
                <>
                    <h1 className="md:text-[74px] text-[21px] text-center font-semibold md:leading-[80px] text-[#F5F4F6]">
                        Empowering Your <span className="text-[#A3F9FE]">Financial Future</span> <br /> with <span className="text-[#A3F9FE]">Infinito Capital</span>
                    </h1>
                    <p className='md:text-[40px] text-[14px] text-center font-medium text-white'>
                        Tailored financial solutions in real estate, retail, and <br /> loans to unlock growth and stability.
                    </p>
                    <Link to='https://bit.ly/tkambious-sgomez' target='_blank' className='duration-300 flex items-center md:text-[34px] mt-5 bg-darklight group hover:bg-secondary text-white rounded-lg gap-4 py-2 pl-4 pr-2'>Schedule a Meeting <GoArrowRight className='bg-secondary group-hover:bg-darklight md:py-2 py-1 md:text-5xl text-3xl rounded-lg' /></Link>
                </>
            ) : (
                <>
                    <h1 className="md:text-[74px] text-[21px] text-center font-semibold md:leading-[80px] text-[#F5F4F6]">
                        Potenciando su <span className='text-[#A3F9FE]'>futuro financiero</span> <br /> con <span className='text-[#A3F9FE]'>Infinito Capital</span>
                    </h1>
                    <p className='md:text-[40px] text-[14px] text-center font-medium text-white'>
                    Soluciones financieras personalizadas en bienes raíces, comercio minorista y <br /> préstamos para desbloquear el crecimiento y la estabilidad.
                    </p>
                    <Link to='https://bit.ly/tkambious-sgomez' target='_blank' className='duration-300 flex items-center md:text-[34px] mt-5 bg-darklight group hover:bg-secondary text-white rounded-lg gap-4 py-2 pl-4 pr-2'>Programe una llamada <GoArrowRight className='bg-secondary group-hover:bg-darklight md:py-2 py-1 md:text-5xl text-3xl rounded-lg' /></Link>
                </>
            )}


        </div>
    )
}

export default Hero
