import React, { useContext } from 'react'
import { LanguageContext } from '../../../context/LanguageContext'


const data = {
    en:{
        lefth:"Request Quote",
        leftdesc:"Discover how we create tailored solutions to drive your success at every step.",
        fullname:"Full Name",
        email:"Email",
        website:"Website",
        message:"Write a Message",
        btn:"Send Message",

        rightheading:"Your Growth, Our Commitment—Try Infinito Capital Today.",
        rightdesc:"Partner with Infinito Capital for tailored financial solutions that meet your needs. Start building your future today-Request Quote now!"
    },
    es:{
        lefth:"Solicitar Cotización",
        leftdesc:"Descubra cómo creamos soluciones personalizadas para impulsar su éxito en cada paso.",
        fullname:"Nombre completo",
        email:"Email",
        website:"Website",
        message:"Escribe un mensaje",
        btn:"Enviar mensajee",

        rightheading:"Su crecimiento, nuestro compromiso: pruebe Infinito Capital hoy.",
        rightdesc:"Asóciese con Infinito Capital para obtener soluciones financieras personalizadas que satisfagan sus necesidades. Comience a construir su futuro hoy: ¡Solicite una cotización ahora!"
    },
}

const ContactForm = () => {
    const {language} = useContext(LanguageContext);
    const d = data[language];
  return (
    <div className='md:my-[144px] my-[64px] max-w-[1400px] md:mx-auto mx-4 '>
      <div className="md:flex justify-between items-center">
        <div className='md:w-[669px]'>
            <h1 className="md:text-[48px] text-[] font-semibold text-dark">{d.lefth}</h1>
            <p className='md:text-[28px] text-[16px] text-dark/70'>{d.leftdesc}</p>
            <div className='mt-[24px]'>
                <input type="text" placeholder={d.fullname} className='placeholder-dark/70 py-4 px-6 outline-none border w-full' />
                <div className="flex gap-4 my-4">
                    <input type="text" placeholder={d.email} className='placeholder-dark/70 py-4 px-6 outline-none border w-full' />
                    <input type="text" placeholder={`${d.website} (Optional)`} className='placeholder-dark/70 py-4 px-6 outline-none border w-full' />
                </div>
                <textarea name="" id="" placeholder={d.message} rows={12} className='placeholder-dark/70 py-4 px-6 outline-none border w-full' ></textarea>
                <button className='bg-primary w-full text-white rounded-xl py-3 md:text-[28px] mt-6'>{d.btn}</button>
            </div>
        </div>
        <div className='md:w-[500px] md:mt-0 mt-8'>
            <h1 className="md:text-[56px] text-[24px] text-primary leading-[1.2] mb-[48px]">{d.rightheading}</h1>
            <p className='md:text-[32px] text-[16px] text-dark'>{d.rightdesc}</p>
        </div>
      </div>
    </div>
  )
}

export default ContactForm
