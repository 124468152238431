import React, { useContext } from 'react'
import solutionIcon from '../../../../assets/solution.png'
import monitoringIcon from '../../../../assets/monitoring.png'
import { LanguageContext } from '../../../../context/LanguageContext'

const data = {
    en:[
        {
            name:"Flexible Solutions",
            desc:"Businesses can sell accounts receivable to Infinito Capital for immediate liquidity, ensuring smooth cash flow and flexible financial support.",
            icon:solutionIcon
        },
        {
            name:"Ongoing Monitoring",
            desc:"Infinito Capital ensures continuous oversight of accounts receivable, maintaining steady cash flow and minimizing disruptions.",
            icon:monitoringIcon
        },
    ],
    es:[
        {
            name:"Soluciones flexibles",
            desc:"Las empresas pueden vender cuentas por cobrar a Infinito Capital para obtener liquidez inmediata, garantizando un flujo de caja fluido y un apoyo financiero flexible.",
            icon:solutionIcon
        },
        {
            name:"Monitoreo continuo",
            desc:"Infinito Capital garantiza una supervisión continua de las cuentas por cobrar, manteniendo un flujo de caja estable y minimizando las interrupciones.",
            icon:monitoringIcon
        },
    ],
}


const Sol = () => {
    const {language} = useContext(LanguageContext);
    const d = data[language] || [];
  return (
    <div className='md:my-[144px] my-[64px] container mx-auto'>
      <div className="md:flex border-b-2">
        {d.map((item,index)=>{
        return(
        <div className='md:border-r-2 p-6' key={index}>
            <img src={item.icon} alt="" />
            <h2 className="font-semibold md:text-[44px] text-[24px] text-primary">{item.name}</h2>
            <p className='text-dark/70 md:text-[32px] text-[16px]'>{item.desc}</p>
        </div>
        )
        })}
      </div>
    </div>
  )
}

export default Sol
