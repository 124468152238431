import React from 'react'
import CallToAction from '../../common_sections/CallToAction'

const LeaseCTA = () => {
    const data = {
        en:{
            title:"Ready to take the Next Step Today?",
            desc:"Empower your business with flexible leasing solutions from Infinito Capital. explore our tailored options that fuel your growth "
        },
        es:{
            title:"¿Listo para dar el siguiente paso hoy?",
            desc:"Potencia tu negocio con soluciones de arrendamiento flexibles de Infinito Capital. Explore nuestras opciones personalizadas que impulsan su crecimiento."
        }
    }
  return (
    <div>
      <CallToAction data={data}/>
    </div>
  )
}

export default LeaseCTA
