import React from 'react'
import CallToAction from '../../common_sections/CallToAction'

const LoansCTA = () => {
    const data = {
        en:{
            title:"Ready to Equip Your Business for Success?",
            desc:"Don’t let financial constraints limit your growth. Contact us today and discover how our tailored credit solutions can empower your business."
        },
        es:{
          title:"¿Listo para equipar su negocio para el éxito?",
          desc:"No permita que las limitaciones financieras limiten su crecimiento. Contáctenos hoy y descubra cómo nuestras soluciones de crédito personalizadas pueden potenciar su negocio."
        }
    }
  return (
    <div>
      <CallToAction data={data}/>
    </div>
  )
}

export default LoansCTA
