import React from 'react'
import MainHero from '../../common_sections/MainHero'

const Hero = () => {
    const data = {
        en:{
            title:"No More Financial Constraints: Empowering Growth with Tailored Credit Solutions",
            desc:"Access the assets your business needs with flexible, fixed-payment plans that fit your cash flow and help you achieve sustainable growth."
        },
        es:{
            title:"No más restricciones financieras: potenciar el crecimiento con soluciones crediticias personalizadas",
            desc:"Acceda a los activos que su empresa necesita con planes de pago fijos y flexibles que se adaptan a su flujo de caja y le ayudan a lograr un crecimiento sostenible."
        },
    }
  return (
    <div>
      <MainHero
      info={data}
      />
    </div>
  )
}

export default Hero
