import React from 'react'
import Process from '../../common_sections/Process'
import img from '../../../../assets/finance_inventory_process.png'

const InventoryProcess = () => {
    const data = {
        en: [
            {
                title: "Inventory Deposit",
                desc: "Inventory is stored with supervision programs in place and notification is sent to Infinito Capital, along with a receipt certificate."
            },
            {
                title: "Fund Disbursement",
                desc: "A margin and rate are set, and funds are disbursed based on the inventory's value."
            },
            {
                title: "Inventory Retrieval",
                desc: "When needed, inventory is retrieved by paying the initial purchase price plus the agreed financial premium."
            },
            {
                title: "Success",
                desc: "Upon payment, inventory is released and delivered to the supplier or customer."
            },
        ],
        es: [
            {
                title: "Depósito de inventario",
                desc: "El inventario se almacena con programas de supervisión implementados y se envía una notificación a Infinito Capital, junto con un certificado de recibo."
            },
            {
                title: "Desembolso de fondos",
                desc: "Se fijan un margen y una tasa, y los fondos se desembolsan en función del valor del inventario."
            },
            {
                title: "Recuperación de inventario",
                desc: "Cuando es necesario, el inventario se recupera pagando el precio de compra inicial más la prima financiera acordada."
            },
            {
                title: "Éxito",
                desc: "Tras el pago, el inventario se libera y se entrega al proveedor o cliente."
            },
        ],
        
      }
      
  return (
    <div>
      <Process
      info={data}
      entitle="No more Financial constraint- Scale Faster"
      estitle="No más restricciones financieras: escale más rápido"
      img={img}
      />
    </div>
  )
}

export default InventoryProcess
