import React from 'react'
import Process from '../../common_sections/Process'
import img from '../../../../assets/realestate_process.png'

const RealEstateProcess = () => {
    const data = {
        en: [
            {
                title: "Property Evaluation",
                desc: "Assess the value and potential of your real estate asset to determine available liquidity."
            },
            {
                title: "Liquidity Agreement",
                desc: "Define terms that align with your needs—whether for working capital, asset purchases, or tax optimization."
            },
            {
                title: "Fund Disbursement",
                desc: "Access your funds quickly and use them strategically to drive growth, stabilize cash flow, or reinvest in operations."
            },
            {
                title: "Flexibility in Use",
                desc: "Continue benefiting from your property while utilizing liquidity for business advancements."
            },
        ],
        es: [
            {
                title: "Evaluación de la propiedad",
                desc: "Evalúa el valor y el potencial de tu activo inmobiliario para determinar la liquidez disponible."
            },
            {
                title: "Acuerdo de Liquidez",
                desc: "Defina términos que se alineen con sus necesidades, ya sea para capital de trabajo, compras de activos u optimización fiscal."
            },
            {
                title: "Desembolso de fondos",
                desc: "Acceda a sus fondos rápidamente y utilícelos estratégicamente para impulsar el crecimiento, estabilizar el flujo de efectivo o reinvertir en operaciones."
            },
            {
                title: "Flexibilidad en el uso",
                desc: "Continúe beneficiándose de su propiedad mientras utiliza liquidez para avances comerciales."
            },
        ],
        
      }
      
  return (
    <div>
      <Process
      info={data}
      entitle="No more Financial constraint- Scale Faster"
      estitle="No más restricciones financieras: escale más rápido"
      img={img}
      />
    </div>
  )
}

export default RealEstateProcess
