import React, { useContext, useState } from 'react';
import image from '../../../assets/standout.png';
import minus from '../../../assets/minus.png';
import add from '../../../assets/add.png';
import { LanguageContext } from '../../../context/LanguageContext';
import { motion } from 'framer-motion';

const StandOut = () => {
  const [openIndex, setOpenIndex] = useState(null); // Track which FAQ is open

  const { language } = useContext(LanguageContext);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index); // Toggle specific FAQ
  };

  const faqs = {
    en: [
      {
        name: "Tailored Strategies",
        desc: "Personalized plans designed to fit your unique business and personal needs.",
      },
      {
        name: "Diverse Solutions",
        desc: "Personalized plans designed to fit your unique business and personal needs.",
      },
      {
        name: "Proven Results",
        desc: "Personalized plans designed to fit your unique business and personal needs.",
      },
      {
        name: "Client-Centric",
        desc: "Personalized plans designed to fit your unique business and personal needs.",
      },
    ],
    es: [
      {
        name: "Estrategias a medida",
        desc: "Planes personalizados diseñados para satisfacer sus necesidades comerciales y personales únicas.",
      },
      {
        name: "Soluciones diversas",
        desc: "Planes personalizados diseñados para satisfacer sus necesidades comerciales y personales únicas.",
      },
      {
        name: "Resultados probados",
        desc: "Planes personalizados diseñados para satisfacer sus necesidades comerciales y personales únicas.",
      },
      {
        name: "Centrado en el cliente",
        desc: "Planes personalizados diseñados para satisfacer sus necesidades comerciales y personales únicas.",
      },
    ],
  };

  const faq = faqs[language] || []; // Safely access the language's FAQ or default to an empty array

  return (
    <div className="md:my-[144px] mt-[64px] max-w-[1400px] md:mx-auto mx-3" id='whatwedo'>
      <div className="md:flex gap-[117px]">
        <div className="md:w-[615px] md:mt-3">
          <h1 className="md:text-[48px] text-[24px] text-black font-bold">
            {language === 'en' ? 'What Makes Us Stand Out' : 'Lo que nos hace destacar'}
          </h1>
          <p className="md:text-[28px] text-[16px] text-black/70">
            {language === 'en'
              ? 'At Infinito Capital, we combine expertise and innovation to deliver unparalleled financial solutions.'
              : 'En Infinito Capital, combinamos experiencia e innovación para ofrecer soluciones financieras incomparables.'}
          </p>
          <div className="md:mt-[72px] mt-[33px]">
            {faq.map((i, index) => (
              <div key={index} className='border-b py-3'>
                <div
                  className="flex justify-between items-center cursor-pointer"
                  onClick={() => toggleFAQ(index)}
                >
                  <h3 className="md:text-[32px] text-[18px] text-black font-medium">{i.name}</h3>
                  <img
                    className="md:w-[40px] w-[24px] h-[24px] md:h-[40px]"
                    src={openIndex === index ? minus : add}
                    alt={openIndex === index ? "minus icon" : "add icon"}
                  />
                </div>
                <motion.div
                  initial={{ height: 0 }}
                  animate={{ height: openIndex === index ? 'auto' : 0 }}
                  transition={{ duration: 0.3 }}
                  className="md:text-[24px] text-[14px] text-black/70 overflow-hidden"
                >
                  {i.desc}
                </motion.div>
              </div>
            ))}
          </div>
        </div>
        <div className="md:w-[549px] md:h-auto h-[207px] bg-cover overflow-hidden">
          <img className='w-full h-full object-cover hover:scale-110 duration-200 cursor-pointer' src={image} alt="" />
        </div>
      </div>
    </div>
  );
};

export default StandOut;
