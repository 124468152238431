import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

// Import your custom marker icon
import customIconUrl from '../../../assets/marker-icon.png';

// Create a custom icon
const customIcon = L.icon({
  iconUrl: customIconUrl, // Path to your custom icon image
  iconSize: [38, 38], // Size of the icon [width, height]
  iconAnchor: [19, 38], // Anchor point of the icon [x, y]
  popupAnchor: [0, -38], // Anchor point of the popup relative to the icon
});



const GoogleMapS = () => {
  return (
    <div className="relative w-full md:h-[500px] h-[200px] z-[-44]">
      <MapContainer
        center={[31.7452818, -106.3098617]} // Replace with your latitude and longitude
        zoom={15}
        scrollWheelZoom={false} // Disable mouse scroll zoom
        className="w-full h-full"
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {/* Use the custom icon */}
        <Marker position={[31.7452818, -106.3098617]} icon={customIcon}>
          <Popup>Here is the location!</Popup>
        </Marker>
      </MapContainer>
    </div>
  );
};

export default GoogleMapS;
