import React, { useContext } from 'react'

import dot from '../../../assets/dot.png'
import { LanguageContext } from '../../../context/LanguageContext'
const Process = ({info,entitle,estitle,img}) => {
    const data = info;
    const { language } = useContext(LanguageContext);
    const d = data[language] || [];
    return (
        <div className='md:my-[144px] my-[64px] md:mx-0 mx-4'>
            <h1 className='text-center text-primary md:text-[56px] text-[26px] font-semibold'>{language === 'en'?entitle:estitle}</h1>
            <div className="max-w-[1400px] md:mx-auto md:mt-[56px]">
                <div className="flex">
                    <div className='md:w-[50%] border-t-2'>
                        {d.map((item, index) => {
                            return (
                                <div className='border-b-2 p-6' key={index}>
                                    <div className="flex items-center">
                                        <img className='md:w-[40px] md:h-[40px] w-[20px] h-[20px]' src={dot} alt="" />
                                        <h4 className="md:text-[40px] text-[20px] text-primary font-semibold">{item.title}</h4>
                                    </div>
                                    <p className='text-darklight/80 md:text-[28px] text-[18px] mt-[20px]'>{item.desc}</p>
                                </div>
                            )
                        })}
                    </div>
                    <div className='w-[50%] md:block hidden'><img src={img} alt="" /></div>
                </div>
            </div>
        </div>
    )
}

export default Process
