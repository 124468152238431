import React, { useEffect } from 'react'
import Hero from '../../components/Sections/Services/Credit/Hero'
import FinancialConstraints from '../../components/Sections/Services/Credit/FinancialConstraints'
import CreditProcess from '../../components/Sections/Services/Credit/CreditProcess'
import CreditFAQs from '../../components/Sections/Services/Credit/CreditFAQs'
import CreditCTA from '../../components/Sections/Services/Credit/CreditCTA'
import AOS from 'aos';  // Import AOS
import 'aos/dist/aos.css';
const Credit = () => {
  useEffect(() => {
    // Initialize AOS
    AOS.init({
      duration: 500, 
      once:false,
      mirror: true, 
      offset:150,
    });
  }, []);

  const sections = [<Hero/>,
    <FinancialConstraints/>,
    <CreditProcess/>,
    <CreditFAQs/>,
    <CreditCTA/>]
  return (
    <div>
      {sections.map((item,index)=>{
      return(
        <div key={index} data-aos="fade-up" data-aos-delay={`${index * 100}`}>{item}</div>
      )
    })}
      
    </div>
  )
}

export default Credit
