import React from 'react'
import FAQs from '../../common_sections/FAQs'
const data = {
    en: [
      {
        name: "What is real estate liquidity financing?",
        desc: "It’s a financing solution that converts your real estate value into working capital or tax benefits.",
      },
      {
        name: "How is it different from a loan?",
        desc: "It doesn’t appear as a liability on your balance sheet, and it’s optimized for flexibility.",
      },
      {
        name: "What assets qualify?",
        desc: "Any commercial or investment real estate property.",
      },
      {
        name: "Can the funds be used for any purpose?",
        desc: "Yes, including growth, inventory, payroll, or investments in new assets.",
      },
      {
        name: "What are the key benefits?",
        desc: "Liquidity, tax optimization, and capital for reinvestment.",
      },
    ],
    es: [
      {
        name: "¿Qué es la financiación de liquidez inmobiliaria?",
        desc: "Es una solución financiera que convierte el valor de su inmueble en capital de trabajo o beneficios fiscales.",
      },
      {
        name: "¿En qué se diferencia de un préstamo?",
        desc: "No aparece como un pasivo en su balance y está optimizado para brindar flexibilidad.",
      },
      {
        name: "¿Qué activos califican?",
        desc: "Cualquier propiedad inmobiliaria comercial o de inversión.",
      },
      {
        name: "¿Se pueden utilizar los fondos para cualquier propósito?",
        desc: "Sí, incluido crecimiento, inventario, nómina o inversiones en nuevos activos.",
      },
      {
        name: "¿Cuáles son los beneficios clave?",
        desc: "Liquidez, optimización fiscal y capital para reinversión.",
      },
    ],
    
  };


const ManagementFAQs = () => {
  return (
    <div>
      <FAQs
      data = {data}
      />
    </div>
  )
}

export default ManagementFAQs
