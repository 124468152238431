import React from 'react'
import MainHero from '../../common_sections/MainHero';

const data = {
    en:{
        title:"Immediate Cash Flow with Infinito Capital's Factoring Solutions",
        desc:"converting accounts receivable into fast, reliable cash. Empower your business with liquidity today!"
    },
    es:{
        title:"Flujo de Caja Inmediato con las Soluciones de Factoring de Infinito Capital",
        desc:"convertir cuentas por cobrar en efectivo rápido y confiable. ¡Potencia tu negocio con liquidez hoy!"
    },
}

const Hero = () => {
    
  return (
    <div>
      <MainHero
      info={data}
      />
    </div>
  )
}

export default Hero
