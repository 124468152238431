import React, { useContext } from 'react'
import { LanguageContext } from '../../../context/LanguageContext'
import { Link } from 'react-router-dom'
import { GoArrowRight } from "react-icons/go";

const data = {
    en:{
        heading:"We’re Here to Help",
        desc:"Get in touch with Infinito Capital to explore tailored solutions for your financial needs. Let’s build your future together—contact us today!",
        btn:"Let’s Talk",
    },
    es:{
        heading:"Estamos aquí para ayudar",
        desc:"Póngase en contacto con Infinito Capital para explorar soluciones personalizadas para sus necesidades financieras. Construyamos su futuro juntos: ¡contáctenos hoy!",
        btn:"Let’s Talk",
    },
}

const Help = () => {
    const {language} = useContext(LanguageContext);
    const d = data[language];
  return (
    <div className='max-w-[1400px] md:mx-auto mx-4 md:my-[56px] my-[25px]'>
      <div className="md:flex justify-between gap-[153px]">
        <div>
            <h1 className='text-primary font-semibold md:text-[80px] text-[24px] leading-[1]'>{d.heading}</h1>
            <Link className='duration-300 flex items-center md:text-[34px] mt-5 bg-darklight group hover:bg-secondary text-white rounded-lg gap-4 py-2 md:w-[296px] justify-center'>{d.btn} <GoArrowRight className='bg-secondary group-hover:bg-darklight md:py-2 py-1 md:text-5xl text-3xl rounded-lg' /></Link>
        </div>
        <div className='md:w-[668px] mt-4 '>
            <p className='md:text-[32px] text-[12px] text-dark/70'>{d.desc}</p>
        </div>
      </div>
    </div>
  )
}

export default Help
