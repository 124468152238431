import React from 'react'
import i1 from '../../../../assets/cover-expense.png'
import i2 from '../../../../assets/trade-discount.png'
import i3 from '../../../../assets/manage-cash.png'
import i4 from '../../../../assets/fund-payroll.png'
import i5 from '../../../../assets/purchase-equipments.png'
import i6 from '../../../../assets/diversify-risk.png'
import CommonCards from '../../common_sections/CommonCards'
const MangamentConstraints = () => {
    const data = {
        en: [
            {
              title: "Access Immediate Capital",
              desc: "Convert real estate into liquid funds without selling your asset.",
              icon: i1,
            },
            {
              title: "Tax Deduction",
              desc: "Turn real estate holdings into operational cost deductions.",
              icon: i2,
            },
            {
              title: "Flexible Capital Allocation",
              desc: "Use liquidity to expand, invest in productive assets, or stabilize cash flow.",
              icon: i3,
            },
            {
              title: "Logistics Financing",
              desc: "Covers costs related to logistics and import expenses.",
              icon: i4,
            },
            {
              title: "Optimize Investments",
              desc: "Benefit from the value of your real estate through innovative financing solutions.",
              icon: i5,
            },
            {
              title: "Growth Investments",
              desc: "Use liquid funds for growth strategies, such as new ventures, expanding operations",
              icon: i6,
            },
          ],
        es: [
          {
            title: "Acceso inmediato a capital",
            desc: "Convierta bienes raíces en fondos líquidos sin vender su activo.",
            icon: i1,
          },
          {
            title: "Deducción de impuestos",
            desc: "Convertir propiedades inmobiliarias en deducciones de costos operativos.",
            icon: i2,
          },
          {
            title: "Asignación de capital flexible",
            desc: "Usar liquidez para expandirse, invertir en activos productivos o estabilizar el flujo de efectivo.",
            icon: i3,
          },
          {
            title: "Financiamiento Logístico",
              desc: "Cubre costos relacionados con logística y gastos de importación.",
              icon: i4,
          },
          {
            title: "Optimizar las Inversiones",
            desc: "Benefíciese del valor de su inmueble a través de soluciones de financiación innovadoras.",
            icon: i5,
          },
          {
            title: "Inversiones de crecimiento",
            desc: "Usar fondos líquidos para estrategias de crecimiento, como nuevas empresas y operaciones en expansión",
            icon: i6,
          },
          ],
        
    }
  return (
    <div>
      <CommonCards
      data={data}
      entitle="Zero-Financial Constraints "
      estitle="Restricciones financieras cero"
      />
    </div>
  )
}

export default MangamentConstraints
