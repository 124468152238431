import React from 'react'
import MainHero from '../../common_sections/MainHero'

const Hero = () => {
    const data = {
        en:{
            title:"Strengthen Your Business with Strategic Loan Solutions",
            desc:"Access the financial flexibility needed to fuel business growth, manage operational expenses, and seize new opportunities with our loan options."
        },
        es:{
            title:"Fortalezca su negocio con soluciones de préstamos estratégicos",
            desc:"Acceda a la flexibilidad financiera necesaria para impulsar el crecimiento empresarial, gestionar los gastos operativos y aprovechar nuevas oportunidades con nuestras opciones de préstamos."
        },
    }
  return (
    <div>
      <MainHero 
      info={data}
      />
    </div>
  )
}

export default Hero
